import React from 'react'
import styles from './BaseInput.module.scss'

export default ({
    value,
    onChange,
    placeholder,
    icon,
    type = 'text',
    styleType = 'dark',
    className = '',
    required = false,
}) => {
    return (
        <div
            className={`${styles.container} ${styles[styleType]} ${className}`}
        >
            <div className={styles.content}>
                {icon}
                <input
                    className={styles.input}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    type={type}
                    placeholder={placeholder}
                    required={required}
                />
            </div>
        </div>
    )
}
