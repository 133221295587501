import React, { useEffect, useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import EventTasks from './EventTasks'
import EventHeader from './EventHeader'
import EventTopBar from './EventTopBar'
import EventJudges from './EventJudges'
import EventResults from './EventResults'
import EventTimeline from './EventTimeline'
import EventPrizeFund from './EventPrizeFund'
import EventDetailContext from '../context'
import AnalyticsService from 'services/analytics'
import styles from './Event.module.scss'
import ItPurpleHack from './ItPurpleHack'

export default () => {
    const { slug, event, registration } = useContext(EventDetailContext)

    const keywords = event.name.split(' ').join(', ')

    const coverImage = () => {
        if (event.coverImage !== null) return event.coverImage.url
        else return '%REACT_APP_SEO_IMAGE_URL%'
    }

    const eventDescription = () =>
        `${event.name} is coming up! If you're interested in joining the coolest hackathon on the planet just head straight to ...`

    useEffect(() => {
        if (slug) {
            AnalyticsService.events.VIEW_EVENT(slug)
        }
    }, [slug])

    // if (event.slug === 'it-purple-hack' || true) {
    //     return (
    //         <ItPurpleHack
    //             hasRegistration={
    //                 registration ? registration.status !== 'incomplete' : false
    //             }
    //         />
    //     )
    // }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{event.name}</title>
                <meta name="keywords" content={keywords} />
                <meta name="title" content={event.name} />
                <meta property="og:title" content={event.name} />
                <meta name="twitter:title" content={event.name} />
                <meta
                    name="description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />
                <meta
                    property="og:description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />
                <meta
                    name="twitter:description"
                    content={
                        event.metaDescription !== null
                            ? event.metaDescription
                            : eventDescription()
                    }
                />
                <meta name="og:type" content="website" />
                <meta property="og:image" content={coverImage()} />
                <meta name="twitter:image" content={coverImage()} />
            </Helmet>

            <EventTopBar event={event} registration={registration} />
            <EventHeader event={event} registration={registration} />

            <div className={styles.eventBody}>
                <EventResults event={event} />
                <EventTasks event={event} />
                <EventTimeline event={event} />
                <EventPrizeFund event={event} />
                <EventJudges event={event} />
            </div>
        </>
    )
}
