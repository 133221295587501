import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import Footer from 'components/layouts/Footer'
import Navbar from 'components/navbars/GlobalNavBar'
import PageWrapper from 'components/layouts/PageWrapper'

export default () => {
    const { t } = useTranslation()

    return (
        <PageWrapper header={() => <Navbar />} footer={() => <Footer />}>
            <Typography
                variant="body1"
                style={{
                    padding: '10px',
                    maxWidth: '1140px',
                    margin: '0 auto',
                }}
                component="div"
            >
                <div dangerouslySetInnerHTML={{ __html: t('Privacy__') }} />
            </Typography>
        </PageWrapper>
    )
}
