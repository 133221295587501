import React, { useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styles from './EventTimeline.module.scss'
import 'moment/locale/ru'

export default ({ event }) => {
    const { t, i18n } = useTranslation()

    const dates = useMemo(() => {
        moment.locale(i18n.language)

        const upToFormat = date =>
            moment(date)
                .format(`[${t('Up_to_')}] D MMMM, HH:mm`)
                .toLowerCase()

        const defaultFormat = date =>
            moment(date).format('D MMMM, HH:mm').toLowerCase()

        const severalDaysFormat = (date1, date2) => {
            const mt1 = moment(date1)
            const mt2 = moment(date2)

            if (mt1.month() === mt2.month()) {
                if (mt1.day() === mt2.day()) {
                    return `${mt1.format('D MMMM')} `
                }
                return `${mt1.format('D')} - ${mt2.format(
                    'D MMMM',
                )}`.toLowerCase()
            } else {
                return `${mt1.format('D MMMM')} - ${mt2.format(
                    'D MMMM',
                )}`.toLowerCase()
            }
        }

        return [
            {
                text: upToFormat(event.registrationEndTime),
                title: t('Registration_'),
                isActive: moment().isBetween(
                    event.registrationStartTime,
                    event.registrationEndTime,
                ),
            },
            {
                text: defaultFormat(event.startTime),
                title: t('Event_start_'),
                isActive: moment().isBetween(
                    event.registrationEndTime,
                    event.submissionsStartTime,
                ),
            },
            {
                text: upToFormat(event.submissionsEndTime),
                title: t('Submissions_'),
                isActive: moment().isBetween(
                    event.submissionsStartTime,
                    event.submissionsEndTime,
                ),
            },
            {
                text: severalDaysFormat(
                    event.reviewingStartTime,
                    event.reviewingEndTime,
                ),
                title: t('Judging_'),
                isActive: moment().isBetween(
                    event.reviewingStartTime,
                    event.reviewingEndTime,
                ),
            },
            {
                text: defaultFormat(event.endTime),
                title: t('Awarding_of_the_winners_'),
                isActive: moment(event.reviewingEndTime).isBefore(),
            },
        ]
    }, [
        i18n.language,
        event.reviewingStartTime,
        event.registrationEndTime,
        event.startTime,
        event.submissionsStartTime,
        event.submissionsEndTime,
        event.reviewingStartTime,
        event.reviewingEndTime,
        event.endTime,
    ])

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('Event_dates_')}</h2>
            <div className={styles.items}>
                <div className={`${styles.itemsGroup} ${styles.itemsGraphs}`}>
                    {dates.map((date, idx) => (
                        <span
                            className={`${styles.itemGraph} ${
                                date.isActive ? styles.active : ''
                            }`}
                            key={idx}
                        ></span>
                    ))}
                </div>
                <div className={styles.itemsGroup}>
                    {dates.map((date, idx) => (
                        <div
                            className={`${styles.itemText} ${styles.item} ${
                                date.isActive ? styles.active : ''
                            }`}
                            key={idx}
                        >
                            <h6>{date.text}</h6>
                            <span className={styles.itemMobileTitle}>
                                {date.title}
                            </span>
                        </div>
                    ))}
                </div>
                <div
                    className={`${styles.itemsGroup} ${styles.itemDesktopTitles}`}
                >
                    {dates.map((date, idx) => (
                        <span
                            className={`${styles.item} ${styles.itemTitle} ${
                                date.isActive ? styles.active : ''
                            }`}
                            key={idx}
                        >
                            {date.title}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}
