import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import EventList from './EventList'
import EventFilters from './EventFilters'
import Footer from 'components/layouts/Footer'
import BaseInput from 'components/inputs/BaseInput'
import BaseButton from 'components/buttons/BaseButton'
import BurgerMenu from 'components/generic/BurgerMenu'
import PageWrapper from 'components/layouts/PageWrapper'
import OutgoingBox from 'components/generic/OutgoingBox'
import GlobalNavBar from 'components/navbars/GlobalNavBar'
import { GET_AVAILABLE_EVENTS } from 'graphql/queries/events'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg'
import { ReactComponent as LoadingIcon } from 'assets/icons/loading.svg'
import config from 'constants/config'
import styles from './IndexPage.module.scss'

const EVENTS_LIMIT = 8

export default () => {
    const { t } = useTranslation()

    const [tempSearchQuery, setTempSearchQuery] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    const onTempSearchChange = val => {
        setTempSearchQuery(val)
        if (!val) setSearchQuery('')
    }

    const [filters, setFilters] = useState({})
    const [sorting, setSorting] = useState('date')
    const [eventsData, setEventsData] = useState({
        count: 0,
        events: [],
    })
    const [isRefetching, setIsRefetching] = useState(false)
    const defaultFetchVars = React.useMemo(
        () => ({
            filters: {
                ...filters,
                query: searchQuery,
                skip: 0,
                limit: EVENTS_LIMIT,
            },
            sorting,
        }),
        [],
    )
    const { data, loading, refetch, fetchMore } = useQuery(
        GET_AVAILABLE_EVENTS,
        {
            variables: defaultFetchVars,
            notifyOnNetworkStatusChange: true,
        },
    )

    useEffect(() => {
        if (data?.availableEvents) setEventsData(data.availableEvents)
    }, [data])

    useEffect(() => {
        setIsRefetching(true)
        refetch({
            filters: {
                ...filters,
                query: searchQuery,
                skip: 0,
                limit: EVENTS_LIMIT,
            },
            sorting,
        }).then(() => {
            setIsRefetching(false)
        })
    }, [filters, sorting, searchQuery])

    const fetchMoreEvents = () => {
        fetchMore({
            variables: {
                filters: {
                    ...filters,
                    query: searchQuery,
                    skip: eventsData.events.length,
                    limit: EVENTS_LIMIT,
                },
                sorting,
            },
            updateQuery: (prev, { fetchMoreResult: now }) => {
                return {
                    availableEvents: {
                        count: now.availableEvents.count,
                        events: [
                            ...prev.availableEvents.events,
                            ...now.availableEvents.events.filter(
                                event =>
                                    !prev.availableEvents.events.find(
                                        i => i.slug === event.slug,
                                    ),
                            ),
                        ],
                        __typename: 'availableEvents',
                    },
                }
            },
        })
    }

    const sortingData = [
        { key: 'date', text: t('By_event_date_') },
        { key: 'participants', text: t('By_participants_count_') },
        { key: 'fund', text: t('By_prize_fund_') },
    ]

    const resetFilters = () => setFilters({})

    const [isSortingBoxShowed, setIsSortingBoxShowed] = useState(false)
    const [isFiltersBoxShowed, setIsFiltersBoxShowed] = useState(false)

    return (
        <PageWrapper
            header={() => <GlobalNavBar />}
            footer={() => <Footer />}
            noOldStyle
        >
            <Helmet>
                <title>{config.PLATFORM_OWNER_NAME}</title>
                <meta
                    name="keywords"
                    content="Hackathon, hackathon platform, Geekbattle"
                />
                <meta name="title" content={config.SEO_PAGE_TITLE} />
                <meta property="og:title" content={config.SEO_PAGE_TITLE} />
                <meta name="twitter:title" content={config.SEO_PAGE_TITLE} />
                <meta
                    name="description"
                    content={config.SEO_PAGE_DESCRIPTION}
                />
                <meta
                    property="og:description"
                    content={config.SEO_PAGE_DESCRIPTION}
                />
                <meta
                    name="twitter:description"
                    content={config.SEO_PAGE_DESCRIPTION}
                />
                <meta name="og:type" content="website" />
                <meta property="og:image" content={config.SEO_IMAGE_URL} />
                <meta name="twitter:image" content={config.SEO_IMAGE_URL} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content={config.SEO_TWITTER_HANDLE} />
                <meta
                    name="twitter:creator"
                    content={config.SEO_TWITTER_HANDLE}
                />
            </Helmet>

            <div className={styles.intro}>
                <h1 className={styles.introTitle}>GeekBattle</h1>
                <h4 className={styles.introDescription}>
                    {t('Online_hackathon_platform_')}
                </h4>
                <form
                    onSubmit={e => {
                        e.preventDefault()
                        setSearchQuery(tempSearchQuery)
                    }}
                    style={{ maxWidth: '100%' }}
                >
                    <BaseInput
                        value={tempSearchQuery}
                        onChange={onTempSearchChange}
                        placeholder={t('Search_by_name_company_tags_')}
                        icon={<SearchIcon />}
                        styleType="light"
                        className={styles.introSearch}
                    />
                </form>
            </div>

            <div className={styles.content}>
                <EventFilters
                    className={styles.sidebar}
                    value={filters}
                    onChange={setFilters}
                    searchQuery={searchQuery}
                />
                <div className={styles.main}>
                    <div className={styles.mainHeader}>
                        <h3>
                            {t('Hackathons_with_number_')}: {eventsData.count}
                        </h3>
                        <div
                            className={`${styles.mainHeaderActions} ${styles.mobile}`}
                        >
                            <BurgerMenu
                                className={styles.sortingMobileBtn}
                                items={sortingData.map(i => ({
                                    content: `${t(
                                        'By_2_',
                                    )} ${i.text.toLowerCase()}`,
                                    onClick: () => setSorting(i.key),
                                }))}
                            >
                                <BaseButton
                                    className={styles.mainHeaderActionsBtn}
                                    styleType="text"
                                >
                                    <SortingIcon />
                                    <span>
                                        {t('By_2_')}{' '}
                                        {sortingData
                                            .find(i => i.key === sorting)
                                            .text.toLowerCase()}
                                    </span>
                                </BaseButton>
                            </BurgerMenu>
                            <BaseButton
                                className={`${styles.mainHeaderActionsBtn} ${styles.sortingXMobileBtn}`}
                                onClick={() =>
                                    setIsSortingBoxShowed(!isSortingBoxShowed)
                                }
                                styleType="text"
                            >
                                <SortingIcon />
                                <span>
                                    {t('By_2_')}{' '}
                                    {sortingData
                                        .find(i => i.key === sorting)
                                        .text.toLowerCase()}
                                </span>
                            </BaseButton>
                            <BaseButton
                                className={styles.mainHeaderActionsBtn}
                                styleType="text"
                                onClick={() => setIsFiltersBoxShowed(true)}
                            >
                                <span
                                    className={
                                        Object.keys(filters).length
                                            ? styles.mainHeaderActionsBtnActiveIcon
                                            : ''
                                    }
                                >
                                    <FilterIcon />
                                </span>
                                <span>{t('Filter_')}</span>
                            </BaseButton>
                        </div>
                        <div
                            className={`${styles.mainHeaderActions} ${styles.desktop}`}
                        >
                            <h8>{t('Sort_by_')}:</h8>
                            <div className={styles.sortingItems}>
                                {sortingData.map((item, idx) => (
                                    <BaseButton
                                        className={`${styles.sortingItem} ${
                                            sorting === item.key
                                                ? styles.active
                                                : ''
                                        }`}
                                        styleType="text"
                                        key={idx}
                                        onClick={() => setSorting(item.key)}
                                    >
                                        {item.text}
                                    </BaseButton>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!isRefetching && (
                        <EventList
                            events={eventsData.events}
                            infiniteScroll={
                                !loading &&
                                eventsData.events.length < eventsData.count
                            }
                            onScrollBottom={fetchMoreEvents}
                        />
                    )}
                    {loading && <LoadingIcon className={styles.loading} />}
                </div>
            </div>
            <OutgoingBox
                value={isSortingBoxShowed}
                onChange={setIsSortingBoxShowed}
                title={t('Sort_')}
            >
                <div className={styles.sortingItems}>
                    {sortingData.map((item, idx) => (
                        <BaseButton
                            className={`${styles.sortingItem} ${
                                sorting === item.key ? styles.active : ''
                            }`}
                            styleType="text"
                            key={idx}
                            onClick={() => {
                                setIsSortingBoxShowed(false)
                                setSorting(item.key)
                            }}
                        >
                            {t('By_2_')} {item.text.toLowerCase()}
                        </BaseButton>
                    ))}
                </div>
            </OutgoingBox>
            <OutgoingBox
                value={isFiltersBoxShowed}
                onChange={setIsFiltersBoxShowed}
                title={t('Filter_')}
            >
                <EventFilters
                    value={filters}
                    onChange={setFilters}
                    searchQuery={searchQuery}
                />
                <div className={styles.filtersActions}>
                    <BaseButton
                        className={styles.filtersMobileAction}
                        onClick={resetFilters}
                        styleType="secondary"
                        disabled={Object.values(filters).every(
                            val => !val.length && val !== true,
                        )}
                    >
                        {t('Reset_')}
                    </BaseButton>
                    <BaseButton
                        className={styles.filtersXMobileAction}
                        styleType="text"
                        onClick={resetFilters}
                    >
                        {t('Reset_')}
                    </BaseButton>
                    <BaseButton onClick={() => setIsFiltersBoxShowed(false)}>
                        {t('Show_hackathons_')}: {eventsData.count}
                    </BaseButton>
                </div>
            </OutgoingBox>
        </PageWrapper>
    )
}
