import React from 'react'
import { useTranslation } from 'react-i18next'
import Carousel from 'components/generic/Carousel'
import styles from './EventJudges.module.scss'
import UserImg from 'assets/images/user.svg'

export default ({ event }) => {
    const { t } = useTranslation()

    if (!event.judges || !event.judges.length) return null

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('Judges_')}</h2>
            <Carousel items={event.judges} itemsPerSlide={4}>
                {judge => (
                    <div
                        className={styles.judge}
                        key={judge.name + judge.about}
                    >
                        <img
                            className={styles.judgeImage}
                            src={judge.image?.url || UserImg}
                        />
                        <div className={styles.judgeInfo}>
                            <h6 className={styles.judgeName}>{judge.name}</h6>
                            <div className={styles.judgeAbout}>
                                {judge.about}
                            </div>
                        </div>
                    </div>
                )}
            </Carousel>
        </div>
    )
}
