import React from 'react'
import { ReactComponent as TimesIcon } from 'assets/icons/times.svg'
import styles from './OutgoingBox.module.scss'

export default ({
    value: isShowed,
    onChange: setIsShowed,
    title,
    children,
}) => {
    return (
        <div className={`${styles.container} ${isShowed ? styles.active : ''}`}>
            <div
                className={styles.backdrop}
                onClick={() => setIsShowed(false)}
            ></div>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h5>{title}</h5>
                    <TimesIcon onClick={() => setIsShowed(!isShowed)} />
                </div>
                {children}
            </div>
        </div>
    )
}
