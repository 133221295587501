import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BaseButton from 'components/buttons/BaseButton'
import EventUtils from 'utils/events'
import styles from './EventTopBar.module.scss'

export default ({ event, registration }) => {
    const { t } = useTranslation()
    const routeMatch = useRouteMatch()

    const hasRegistration = registration
        ? registration.status !== 'incomplete'
        : false

    const isRegistrationOpen = moment().isBetween(
        event.registrationStartTime,
        event.registrationEndTime,
    )

    const [isShowed, setIsShowed] = useState(false)

    const onScroll = () => {
        const el = document.querySelector('#eventInfo')

        if (window.scrollY > el.offsetTop + 1) {
            setIsShowed(true)
        } else {
            setIsShowed(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return (
        <div
            className={`${styles.container} ${isShowed ? styles.active : ''} `}
        >
            <div className={styles.content}>
                <div>
                    <h6 className={styles.eventName}>{event.name}</h6>
                    <span className={styles.eventDates}>
                        {EventUtils.getEventDates(event)}
                    </span>
                </div>
                {!hasRegistration && isRegistrationOpen && (
                    <>
                        <BaseButton
                            className={styles.desktopButton}
                            link={`${routeMatch.url}/register`}
                        >
                            {t('Take_part_')}
                        </BaseButton>
                        <BaseButton
                            className={styles.mobileButton}
                            link={`${routeMatch.url}/register`}
                            styleType="text"
                        >
                            {t('Take_part_')}
                        </BaseButton>
                    </>
                )}
            </div>
        </div>
    )
}
