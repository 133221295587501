import moment from 'moment'
import EventConstants from 'constants/events'

const EventUtils = {
    getEventStatus: event => {
        const now = moment()

        if (now.isBefore(event.registrationStartTime)) {
            return EventConstants.STATUS.Published
        }

        if (now.isBefore(event.registrationEndTime)) {
            return EventConstants.STATUS.Registration
        }

        if (now.isBefore(event.startTime)) {
            return EventConstants.STATUS.Confirmation
        }

        if (now.isBefore(event.endTime)) {
            return EventConstants.STATUS.InProgress
        }

        return EventConstants.STATUS.Finished
    },

    getEventDates: event => {
        const mt1 = moment(event.startTime)
        const mt2 = moment(event.endTime)

        if (mt1.isSame(mt2, 'day')) {
            return `${mt1.format('D MMMM')}`
        } else if (mt1.isSame(mt2, 'month')) {
            return `${mt1.format('D')} - ${mt2.format('D MMMM')}`
        } else {
            return `${mt1.format('D MMMM')} - ${mt2.format('D MMMM')}`
        }
    },

    transformMoney: value =>
        `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 0} ₽`,
}

export default EventUtils
