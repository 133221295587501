import React from 'react'
import styles from './EventTagList.module.scss'

export default ({ items: tags, className = '', itemClassName = '' }) => {
    return (
        <div className={`${styles.container} ${className}`}>
            {tags.map(
                tag =>
                    tag && (
                        <span
                            className={`${styles.tag} ${itemClassName}`}
                            key={tag.name}
                        >
                            {tag.name}
                        </span>
                    ),
            )}
        </div>
    )
}
