import React, { useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styles from './EventStatus.module.scss'

export default ({ event, className = '' }) => {
    const { t } = useTranslation()

    const status = useMemo(() => {
        if (
            moment(event.startTime).isBefore() &&
            moment(event.endTime).isBefore()
        ) {
            return 'past'
        } else if (
            moment(event.startTime).isSameOrBefore() &&
            moment(event.endTime).isAfter()
        ) {
            return 'present'
        } else {
            return 'future'
        }
    }, [event.startTime, event.endTime])

    return (
        <div className={`${styles.container} ${className}`}>
            <span className={`${styles.light} ${styles[status]}`}></span>
            <span>{t(`Event_in_${status}_`)}</span>
        </div>
    )
}
