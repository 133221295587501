import React, { useState } from 'react'
import BaseButton from 'components/buttons/BaseButton'
import { ReactComponent as ChevronLeftIcon } from 'assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg'
import styles from './Carousel.module.scss'

export default ({
    items,
    itemsPerSlide = 1,
    children,
    className = '',
    itemsClassName = '',
    noMobile = false,
}) => {
    const [activeIdx, setActiveIdx] = useState(0)

    const itemsGroups = items.reduce((result, _value, idx, arr) => {
        if (idx % itemsPerSlide === 0)
            result.push(arr.slice(idx, idx + itemsPerSlide))
        return result
    }, [])

    const slideLeft = () => activeIdx > 0 && setActiveIdx(activeIdx - 1)
    const slideRight = () =>
        activeIdx < itemsGroups.length - 1 && setActiveIdx(activeIdx + 1)

    return (
        <div
            className={`${styles.container} ${
                noMobile ? styles.noMobile : ''
            } ${className}`}
        >
            {items.length > itemsPerSlide && (
                <BaseButton
                    className={styles.button}
                    disabled={activeIdx <= 0}
                    onClick={slideLeft}
                >
                    <ChevronLeftIcon />
                </BaseButton>
            )}
            <div className={styles.content}>
                <div
                    className={`${styles.items} ${itemsClassName}`}
                    style={{ transform: `translateX(-${activeIdx * 100}%)` }}
                >
                    {itemsGroups.map((visibleItems, idx) => (
                        <div
                            className={`${styles.itemsVisible} ${itemsClassName}`}
                            key={idx}
                        >
                            {visibleItems.map(item => children(item))}
                        </div>
                    ))}
                </div>
            </div>
            {items.length > itemsPerSlide && (
                <BaseButton
                    className={styles.button}
                    disabled={activeIdx >= itemsGroups.length - 1}
                    onClick={slideRight}
                >
                    <ChevronRightIcon />
                </BaseButton>
            )}
        </div>
    )
}
