const abstractSkills = require('../data/skills-abstract.json')
const programmingLanguages = require('../data/skills-programming-languages.json')
const programmingTools = require('../data/skills-programming-frameworks-tools.json')
const toolsAndSoftware = require('../data/skills-tools-and-software.json')

const categories = [
    {
        id: 'abstract-skills',
        label: 'Abstract Skills',
        items: abstractSkills,
    },
    {
        id: 'programming-languages',
        label: 'Programming Languages',
        items: programmingLanguages,
    },
    {
        id: 'programming-tools',
        label: 'Programming Frameworks & Tools',
        items: programmingTools,
    },
    {
        id: 'tools-and-software',
        label: 'Tools & Software',
        items: toolsAndSoftware,
    },
]

const skillLevels = {
    1: {
        label: 'Basic_Proficiency_',
        description: 'Basic_Proficiency_Description_',
    },
    2: {
        label: 'Novice_',
        description: 'Novice_Description_',
    },
    3: {
        label: 'Intermediate_',
        description:'Intermediate_Description_',
    },
    4: {
        label: 'Advanced_',
        description: 'Advanced_Description_',
    },
    5: {
        label: 'Expert_',
        description: 'Expert_Description_',
    },
}

const Skills = {}

Skills.categories = categories
Skills.items = categories.reduce((res, category) => {
    res = res.concat(category.items)
    return res
}, [])
Skills.itemsWithoutAbstract = categories.reduce((res, category) => {
    if (category.id !== 'abstract-skills') {
        res = res.concat(category.items)
    }
    return res
}, [])
Skills.skillLevelArray = Object.keys(skillLevels).map(idx => ({
    value: idx,
    ...skillLevels[idx],
}))
Skills.skillLevelLabelsArray = Object.keys(skillLevels).map(
    idx => skillLevels[idx].label
)
Skills.getLabelForSkillLevel = level =>
    skillLevels.hasOwnProperty(level) ? skillLevels[level].label : ''
Skills.getDescriptionForSkillLevel = level =>
    skillLevels.hasOwnProperty(level) ? skillLevels[level].description : ''

module.exports = Skills
