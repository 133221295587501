import React from 'react'
import BaseButton from 'components/buttons/BaseButton'
import styles from './TagSelect.module.scss'

export default ({ tags, value: activeTags = [], onChange, className }) => {
    const toggleTag = id => {
        if (activeTags.includes(id)) {
            const idx = activeTags.indexOf(id)
            onChange([
                ...activeTags.slice(0, idx),
                ...activeTags.slice(idx + 1),
            ])
        } else {
            onChange([...activeTags, id])
        }
    }

    return (
        <div className={`${styles.container} ${className}`}>
            {tags.map(tag => (
                <BaseButton
                    className={`${styles.tag} ${
                        activeTags.includes(tag._id) ? styles.active : ''
                    }`}
                    styleType="secondary"
                    onClick={() => toggleTag(tag._id)}
                    key={tag.name}
                >
                    {tag.name}
                </BaseButton>
            ))}
        </div>
    )
}
