import React, { useState } from 'react'
import { ReactComponent as TimesIcon } from 'assets/icons/times.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg'
import styles from './EventFiltersBlock.module.scss'

export default ({ title, children, onClear, clearable }) => {
    const [isShowed, setIsShowed] = useState(true)

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.headerTitle}>{title}</span>
                {clearable && (
                    <TimesIcon
                        className={styles.headerIcon}
                        onClick={onClear}
                    />
                )}
                <span
                    className={styles.headerIcon}
                    onClick={() => setIsShowed(!isShowed)}
                >
                    {isShowed ? <ChevronUpIcon /> : <ChevronDownIcon />}
                </span>
            </div>
            <div
                className={`${styles.content} ${isShowed ? styles.active : ''}`}
            >
                {children}
            </div>
        </div>
    )
}
