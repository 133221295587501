import React, { useState } from 'react'
import BaseButton from 'components/buttons/BaseButton'
import styles from './BurgerMenu.module.scss'

export default ({
    items,
    className = '',
    childrenClassName = '',
    itemClassName = '',
    children,
}) => {
    const [isShowed, setIsShowed] = useState(false)

    const onItemClick = itemOnClick => {
        setIsShowed(false)
        return itemOnClick?.()
    }

    return (
        <div
            className={`${styles.container} ${
                isShowed ? styles.active : ''
            } ${className}`}
        >
            <div
                className={childrenClassName}
                onClick={() => setIsShowed(!isShowed)}
            >
                {children}
            </div>
            <div
                className={styles.backdrop}
                onClick={() => setIsShowed(false)}
            ></div>
            <div className={styles.items}>
                {items.map((item, idx) => (
                    <BaseButton
                        className={`${styles.item} ${itemClassName}`}
                        styleType="text"
                        key={idx}
                        link={item.link}
                        onClick={() => onItemClick(item.onClick)}
                    >
                        {item.content}
                    </BaseButton>
                ))}
            </div>
        </div>
    )
}
