import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import Footer from 'components/layouts/Footer'
import PageWrapper from 'components/layouts/PageWrapper'

import Divider from 'components/generic/Divider'
import Button from 'components/generic/Button'

import Container from 'components/generic/Container'
import GlobalNavBar from 'components/navbars/GlobalNavBar'
import PricingCard from 'components/generic/PricingCard'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles } from '@material-ui/core/styles'

import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import Emails from 'constants/email'

const useStyles = makeStyles(theme => ({
    backButtonWrapper: {
        position: 'absolute',
        zIndex: 10,
        width: '100%',
        paddingTop: theme.spacing(1),
    },

    pricingWrapper: {
        width: '100%',
        paddingTop: '2em',
    },
}))

export default () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { t } = useTranslation()
    const body1 = [t('Event_Base_')]
    const body2 = [
        t('Event_Base_'),
        t('Tech_Support_'),
        t('Project_Lead_'),
        t('Private_Events_'),
        t('Event_Pr_'),
    ]
    const body3 = [
        t('Event_Base_'),
        t('Tech_Support_'),
    ]

    return (
        <PageWrapper
            header={() => <GlobalNavBar />}
            footer={() => <Footer />}
            render={() => (
                <>
                    <Container center wrapperClass={classes.backButtonWrapper}>
                        <Button onClick={() => dispatch(push('/'))}>
                            <ArrowBackIosIcon style={{ color: 'black' }} />
                            <Typography
                                variant="button"
                                style={{ color: 'black' }}
                            >
                                {t('Back_')}
                            </Typography>
                        </Button>
                    </Container>
                    <Divider size={3} />
                    <Container center wrapperClass={classes.pricingWrapper}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            spacing={4}
                        >
                            <PricingCard
                                topic="Community"
                                body={body1}
                                price={t('Free_')}
                            />
                            <PricingCard
                                topic="Pro"
                                body={body2}
                                price={t('Ask_', {
                                    email: Emails.Hello,
                                })}
                            />
                            <Divider size={4} />
                            <Typography variant="h6" justify="center">
                                {t('Expertise_')}
                            </Typography>
                            <Divider size={4} />
                            <Button
                                variant="outlined"
                                color="theme_lightgray"
                                strong
                                m={6}
                                onClick={() => dispatch(push('/contact'))}
                            >
                                {t('Contact_us_')}
                            </Button>
                        </Grid>
                    </Container>
                    <Divider size={4} />
                </>
            )}
        />
    )
}
