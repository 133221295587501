const {
    GraphQLDate,
    GraphQLTime,
    GraphQLDateTime,
} = require('graphql-iso-date')

const yup = require('yup')
const _ = require('lodash')
const {
    graphql,
    GraphQlSchema,
    GraphQlObjectType,
    GraphQLString,
    GraphQLList,
    GraphQLNonNull,
    GraphQLInt,
    GraphQLBoolean,
} = require('graphql')

const PhoneNumberSchema = require('../schemas/PhoneNumber')
const RoleSchema = require('../schemas/Role')
const SkillSchema = require('../schemas/Skill')
const EducationSchema = require('../schemas/Education')
const RecruitmentOptionsSchema = require('../schemas/RecruitmentOptions')
const TeamOptionsSchema = require('../schemas/TeamOptions')

const Genders = require('./genders')
const Countries = require('./countries')
const Languages = require('./languages')
const Industries = require('./industries')
const Themes = require('./themes')
const Roles = require('./roles')
const Skills = require('./skills')
const Misc = require('./misc')
const FieldTypes = require('./field-types')
const FilterTypes = require('./filter-types')
const FilterValues = require('./filter-values')

const Categories = {
    basicDetails: {
        id: 'basicDetails',
        label: 'Basic_Details_',
        order: 1,
    },
    skillsAndInterests: {
        id: 'skillsAndInterests',
        label: 'Skills_Interests_',
        order: 2,
    },
    links: {
        id: 'links',
        label: 'Links_',
        order: 3,
    },
    travelAndAccommodation: {
        id: 'travelAndAccommodation',
        label: 'Travel_Accommodation_',
        order: 4,
    },
    recruitment: {
        id: 'recruitment',
        label: 'Opportunities_',
        order: 5,
    },
    other: {
        id: 'other',
        label: 'Other_',
        order: 6,
    },
}

const FieldProps = {
    firstName: {
        label: 'First_name_',
        hint: '',
        hintMarkdown: false,
        placeholder: 'Herbert',
        fieldType: FieldTypes.SHORT_TEXT,
        colSize: 12,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            required: true,
            trim: true,
        },
        graphqlSchema: GraphQLNonNull(GraphQLString),
        alwaysRequired: true,
        schemaConfig: {
            defaultEnable: true,
            defaultRequire: true,
            editable: false,
        },
        filters: [
            {
                path: '',
                label: 'First name',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    lastName: {
        label: 'Last_name_',
        hint: '',
        hintMarkdown: false,
        placeholder: 'Hacker',
        colSize: 12,
        fieldType: FieldTypes.SHORT_TEXT,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            required: true,
            trim: true,
        },
        graphqlSchema: GraphQLNonNull(GraphQLString),
        alwaysRequired: true,
        schemaConfig: {
            defaultEnable: true,
            defaultRequire: true,
            editable: false,
        },
        filters: [
            {
                path: '',
                label: 'Last name',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    email: {
        label: 'Email_',
        hint: '',
        hintMarkdown: false,
        placeholder: 'anonymous.hacker@bighackathon.com',
        fieldType: FieldTypes.EMAIL,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            required: true,
            trim: true,
        },
        graphqlSchema: GraphQLNonNull(GraphQLString),
        alwaysRequired: true,
        schemaConfig: {
            defaultEnable: true,
            defaultRequire: true,
            editable: false,
        },
        filters: [
            {
                path: '',
                label: 'Email',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    phoneNumber: {
        label: 'Phone_number_',
        hint: '',
        hintMarkdown: false,
        fieldType: FieldTypes.PHONE_NUMBER,
        copyToUserProfile: true,
        mongooseSchema: PhoneNumberSchema.mongoose,
        graphqlSchema: PhoneNumberSchema.graphql,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    dateOfBirth: {
        label: 'Date_of_birth_',
        hint: 'At_least_12_years_',
        hintMarkdown: false,
        placeholder: 'Select_date_',
        fieldType: FieldTypes.DATE,
        copyToUserProfile: true,
        mongooseSchema: {
            type: Date,
        },
        graphqlSchema: GraphQLDate,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Date of birth',
                type: FilterTypes.DATE,
                valueType: FilterValues.DATE,
            },
        ],
    },
    gender: {
        label: 'Gender_',
        hint: '',
        hintMarkdown: false,
        fieldType: FieldTypes.GENDER,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            validate: {
                validator(v) {
                    return Genders.some(g => g.value === v)
                },
                message: () =>
                    `Gender must be one of ${Genders.value.join(',')}`,
            },
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Gender',
                type: FilterTypes.STRING,
                valueType: FilterValues.GENDER,
            },
        ],
    },
    nationality: {
        label: 'Nationality_',
        hint: '',
        hintMarkdown: false,
        fieldType: FieldTypes.NATIONALITY,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            validate: {
                validator(v) {
                    return Countries.asArrayOfNationalities.indexOf(v) !== -1
                },
                message: props => `${props.value} is not a valid nationality`,
            },
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Nationality',
                type: FilterTypes.STRING,
                valueType: FilterValues.NATIONALITY,
            },
        ],
    },
    spokenLanguages: {
        label: 'Spoken_language_',
        hint: 'Spoken_language_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.LANGUAGES,
        copyToUserProfile: true,
        mongooseSchema: [
            {
                type: String,
                validate: {
                    validator(v) {
                        return Languages.asArrayOfNames.indexOf(v) !== -1
                    },
                    message: props => `${props.value} is not a valid language`,
                },
            },
        ],
        graphqlSchema: GraphQLList(GraphQLString),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Spoken languages',
                type: FilterTypes.ARRAY,
                valueType: FilterValues.LANGUAGE,
            },
        ],
    },
    countryOfResidence: {
        label: 'Country_of_residence_',
        hint: 'Country_of_residence_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.COUNTRY,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            validate: {
                validator(v) {
                    return Countries.asArrayOfName.indexOf(v) !== -1
                },
                message: props => `${props.value} is not a valid country`,
            },
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Country of Residence',
                type: FilterTypes.STRING,
                valueType: FilterValues.COUNTRY,
            },
        ],
    },
    cityOfResidence: {
        label: 'City_of_residence_',
        hint: 'City_of_residence_Hint_',
        hintMarkdown: false,
        placeholder: 'Hackerville',
        fieldType: FieldTypes.SHORT_TEXT,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    tShirtSize: {
        label: 'Tshirt_size_',
        hint: '',
        hintMarkdown: false,
        fieldType: FieldTypes.T_SHIRT_SIZE,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            validate: {
                validator(v) {
                    return Misc.tShirtSizes.indexOf(v) !== -1
                },
                message: () =>
                    `T-shirt size must be one of ${Misc.tShirtSizes.join(',')}`,
            },
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    dietaryRestrictions: {
        label: 'Dietary_restrictions_',
        hint:
            'Please select all dietary restrictions from the below list that apply to you - if none of the available options apply, you can leave this field empty.',
        hintMarkdown: false,
        fieldType: FieldTypes.DIETARY_RESTRICTIONS,
        copyToUserProfile: false,
        mongooseSchema: [
            {
                type: String,
            },
        ],
        graphqlSchema: GraphQLList(GraphQLString),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    headline: {
        label: 'Headline_',
        hint: 'Headline_hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.SHORT_TEXT,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        graphqlSchema: GraphQLString,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
    },
    biography: {
        label: 'Bio_',
        hint: 'Bio_hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.LONG_TEXT,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        graphqlSchema: GraphQLString,
    },
    roles: {
        label: 'Pro_roles_',
        hint: 'Roles_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.ROLES,
        copyToUserProfile: true,
        mongooseSchema: [RoleSchema.mongoose],
        graphqlSchema: GraphQLList(RoleSchema.graphql),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    skills: {
        label: 'Skills_',
        hint: 'Skills_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.SKILLS,
        copyToUserProfile: true,
        mongooseSchema: [SkillSchema.mongoose],
        graphqlSchema: GraphQLList(SkillSchema.graphql),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    industriesOfInterest: {
        label: 'Industries_of_interest_',
        hint: 'Industries_of_interest_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.INDUSTRIES,
        copyToUserProfile: true,
        mongooseSchema: [
            {
                type: String,
                validate: {
                    validator(v) {
                        return Industries.industries.indexOf(v) !== -1
                    },
                    message: props => `${props.value} is not a valid industry`,
                },
            },
        ],
        graphqlSchema: GraphQLList(GraphQLString),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    themesOfInterest: {
        label: 'Themes_of_interest_',
        hint: 'Themes_of_interest_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.THEMES,
        copyToUserProfile: true,
        mongooseSchema: [
            {
                type: String,
                validate: {
                    validator(v) {
                        return Themes.themes.indexOf(v) !== -1
                    },
                    message: props => `${props.value} is not a valid theme`,
                },
            },
        ],
        graphqlSchema: GraphQLList(GraphQLString),
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    numHackathons: {
        label: 'Number_of_hackathons_attended_',
        hint: 'Number_of_hackathons_attended_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.NUM_HACKATHONS,
        copyToUserProfile: true,
        mongooseSchema: {
            type: Number,
        },
        graphqlSchema: GraphQLInt,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    education: {
        label: 'Education_',
        hint: 'Education_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.EDUCATION,
        copyToUserProfile: true,
        mongooseSchema: EducationSchema.mongoose,
        graphqlSchema: EducationSchema.graphql,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    motivation: {
        label: 'Motivation_',
        hint: 'Motivation_Hint_',
        hintMarkdown: true,
        fieldType: FieldTypes.LONG_TEXT,
        copyToUserProfile: false,
        mongooseSchema: {
            type: String,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequired: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Motivation',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    portfolio: {
        label: 'Portfolio_',
        hint: 'Portfolio_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.URL,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Link to Portfolio',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    curriculumVitae: {
        label: 'CV_',
        hint: 'CV_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.URL,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Link to CV',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    github: {
        label: 'GitHub_',
        hint: 'GitHub_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.URL,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Link to GitHub',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    linkedin: {
        label: 'Linkedin_',
        hint: 'Linkedin_Hint_',
        hintMarkdown: false,
        fieldType: FieldTypes.URL,
        copyToUserProfile: true,
        mongooseSchema: {
            type: String,
            trim: true,
        },
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        graphqlSchema: GraphQLString,
        filters: [
            {
                path: '',
                label: 'LinkedIn profile',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    countryOfTravel: {
        label: 'Country_of_travel_',
        hint: 'Where would you be travelling to the event from?',
        hintMarkdown: false,
        fieldType: FieldTypes.COUNTRY,
        copyToUserProfile: false,
        mongooseSchema: {
            type: String,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Country of Travel',
                type: FilterTypes.STRING,
                valueType: FilterValues.COUNTRY,
            },
        ],
    },
    cityOfTravel: {
        label: 'City_of_travel_',
        hint: 'Which city are you travelling from?',
        hintMarkdown: false,
        fieldType: FieldTypes.SHORT_TEXT,
        copyToUserProfile: false,
        mongooseSchema: {
            type: String,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'City of Travel',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    needsVisa: {
        label: 'Do_you_need_a_visa_',
        hint:
            'Do you need a visa to travel to the event? If you do, we will provide you with an invitation letter to make sure you get one. You can check e.g. here if you need a visa to travel to the event https://www.passportindex.org/comparebyPassport.php',
        hintMarkdown: true,
        fieldType: FieldTypes.BOOLEAN,
        copyToUserProfile: false,
        mongooseSchema: {
            type: Boolean,
        },
        graphqlSchema: GraphQLBoolean,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Link to Portfolio',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
    needsTravelGrant: {
        label: 'Do_you_want_to_apply_for_a_travel_grant_',
        hint:
            "We can't cover all of your travel costs, but we offer the following travel grants for people travelling to the event from farther away: \n\n" +
            '- Finland (outside Greater Helsinki Region): 20€ \n' +
            '- Baltics: 40€ \n' +
            '- Nordics & Russia: 60€ \n' +
            '- Rest of Europe: 80€ \n' +
            '- Outside of Europe: 150€',
        hintMarkdown: true,
        fieldType: FieldTypes.BOOLEAN,
        copyToUserProfile: false,
        mongooseSchema: {
            type: Boolean,
        },
        graphqlSchema: GraphQLBoolean,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Applied for travel grant',
                type: FilterTypes.BOOLEAN,
                valueType: FilterValues.BOOLEAN,
            },
        ],
    },
    needsAccommodation: {
        label: 'Do_you_need_free_accommodation_',
        hint:
            'We can provide a warm space and a roof over your head during the event, where you will need your own sleeping bag and matress. Let us know if you need it, or if you will arrange your own accommodation during the event :)',
        hintMarkdown: false,
        fieldType: FieldTypes.BOOLEAN,
        copyToUserProfile: false,
        mongooseSchema: {
            type: Boolean,
        },
        graphqlSchema: GraphQLBoolean,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Needs accommodation',
                type: FilterTypes.BOOLEAN,
                valueType: FilterValues.BOOLEAN,
            },
        ],
    },
    recruitmentOptions: {
        label: 'Job_opportunities_',
        hint: '',
        fieldType: FieldTypes.RECRUITMENT_OPTIONS,
        copyToUserProfile: true,
        mongooseSchema: RecruitmentOptionsSchema.mongoose,
        graphqlSchema: RecruitmentOptionsSchema.graphql,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
    },
    teamOptions: {
        label: 'Applying_as_a_team_',
        hint:
            'Do you already have people you want to participate with figured out?',
        hintMarkdown: true,
        fieldType: FieldTypes.TEAM_OPTIONS,
        copyToUserProfile: false,
        mongooseSchema: TeamOptionsSchema.mongoose,
        graphqlSchema: TeamOptionsSchema.graphql,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: 'applyAsTeam',
                label: 'Team > Applied as team',
                type: FilterTypes.BOOLEAN,
                valueType: FilterValues.BOOLEAN,
            },
            {
                path: 'applyAlone',
                label: 'Team > Applied also alone',
                type: FilterTypes.BOOLEAN,
                valueType: FilterValues.BOOLEAN,
            },
        ],
    },
    secretCode: {
        label: 'Secret_code_',
        hint:
            "If you've received a secret code for this event, enter it here. Note: this is not the same as your team code, which you will be able to enter after completing your registration.",
        hintMarkdown: false,
        fieldType: FieldTypes.SHORT_TEXT,
        copyToUserProfile: false,
        mongooseSchema: {
            type: String,
        },
        graphqlSchema: GraphQLString,
        schemaConfig: {
            defaultEnable: false,
            defaultRequire: false,
            editable: true,
        },
        filters: [
            {
                path: '',
                label: 'Secret Code',
                type: FilterTypes.STRING,
                valueType: FilterValues.STRING,
            },
        ],
    },
}

const Fields = {
    firstName: {
        ...FieldProps.firstName,
        category: Categories.basicDetails,
        default: (userProfile, idToken) =>
            userProfile.firstName || idToken.given_name || '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(100)
                .label(FieldProps.firstName.label)

            return required ? base.required() : base
        },
    },
    lastName: {
        ...FieldProps.lastName,
        category: Categories.basicDetails,
        default: (userProfile, idToken) =>
            userProfile.lastName || idToken.family_name || '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(200)
                .label(FieldProps.lastName.label)
            return required ? base.required() : base
        },
    },
    email: {
        ...FieldProps.email,
        category: Categories.basicDetails,
        default: (userProfile, idToken) =>
            userProfile.email || idToken.email || '',
        validationSchema: required => {
            const base = yup.string().email().label(FieldProps.email.label)
            return required ? base.required() : base
        },
    },
    phoneNumber: {
        ...FieldProps.phoneNumber,
        category: Categories.basicDetails,
        default: (userProfile, idToken) => userProfile.phoneNumber || undefined,
        validationSchema: required => {
            const countryCode = yup
                .string()
                .oneOf(Countries.asArrayOfPhoneCodes)
                .label('Country code')
            const number = yup.string().label('Phone number')
            const shape = required
                ? {
                      countryCode: countryCode.required(),
                      number: number.matches(/^[0-9]{7,14}$/).required(),
                  }
                : {
                      countryCode,
                      number,
                  }

            return yup.object(shape).label(FieldProps.phoneNumber.label)
        },
    },
    dateOfBirth: {
        ...FieldProps.dateOfBirth,
        category: Categories.basicDetails,
        default: (userProfile, idToken) => userProfile.dateOfBirth || undefined,
        validationSchema: required => {
            const base = yup
                .date()
                .min(new Date(Date.now() - 1000 * 60 * 60 * 24 * 365 * 120))
                .max(new Date(Date.now() - 1000 * 60 * 60 * 24 * 364 * 12))
                .label(FieldProps.dateOfBirth.label)

            return required ? base.required() : base
        },
    },
    gender: {
        ...FieldProps.gender,
        category: Categories.basicDetails,
        default: (userProfile, idToken) => userProfile.gender || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .oneOf(Genders.map(item => item.value))
                .label(FieldProps.gender.label)
            return required ? base.required() : base
        },
    },
    nationality: {
        ...FieldProps.nationality,
        category: Categories.basicDetails,
        default: (userProfile, idToken) => userProfile.nationality || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .oneOf(Countries.asArrayOfNationalities)
                .label(FieldProps.nationality.label)

            return required ? base.required() : base
        },
    },
    spokenLanguages: {
        ...FieldProps.spokenLanguages,
        category: Categories.basicDetails,
        default: (userProfile, idToken) => userProfile.spokenLanguages || [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(yup.string().oneOf(Languages.asArrayOfNames))
                .ensure()
                .label(FieldProps.spokenLanguages.label)

            return required ? base.required() : base
        },
    },
    countryOfResidence: {
        ...FieldProps.countryOfResidence,
        category: Categories.basicDetails,
        default: (userProfile, idToken) =>
            userProfile.countryOfResidence || idToken.country || '',
        validationSchema: required => {
            const base = yup
                .string()
                .oneOf(Countries.asArrayOfName)
                .label(FieldProps.countryOfResidence.label)

            return required ? base.required() : base
        },
    },
    cityOfResidence: {
        ...FieldProps.cityOfResidence,
        category: Categories.basicDetails,
        default: (userProfile, idToken) =>
            userProfile.cityOfResidence || idToken.city || '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(100)
                .label(FieldProps.cityOfResidence.label)
            return required ? base.required() : base
        },
    },
    tShirtSize: {
        ...FieldProps.tShirtSize,
        category: Categories.basicDetails,
        default: userProfile => userProfile.tShirtSize || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .oneOf(Misc.tShirtSizes)
                .label(FieldProps.tShirtSize.label)

            return required ? base.required() : base
        },
    },
    dietaryRestrictions: {
        ...FieldProps.dietaryRestrictions,
        category: Categories.basicDetails,
        default: () => [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(yup.string().oneOf(Misc.dietaryRestrictions))
                .ensure()
                .label(FieldProps.dietaryRestrictions.label)

            return required ? base.required() : base
        },
    },
    headline: {
        ...FieldProps.headline,
        category: Categories.skillsAndInterests,
        default: userProfile => userProfile.headline || '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(100)
                .label(FieldProps.headline.label)
            return required ? base.required() : base
        },
    },
    biography: {
        ...FieldProps.biography,
        category: Categories.skillsAndInterests,
        default: userProfile => userProfile.biography || '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(1000)
                .label(FieldProps.biography.label)

            return required ? base.required() : base
        },
    },
    roles: {
        ...FieldProps.roles,
        category: Categories.skillsAndInterests,
        default: userProfile => userProfile.roles || [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(
                    yup.object().shape({
                        role: yup
                            .string()
                            .oneOf(Roles.items)
                            .required()
                            .label('Role'),
                        years: yup
                            .number()
                            .min(1)
                            .max(5)
                            .required()
                            .label('Years of experience'),
                    }),
                )
                .ensure()
                .max(5)
                .label(FieldProps.roles.label)

            return required ? base.required() : base
        },
    },
    skills: {
        ...FieldProps.skills,
        category: Categories.skillsAndInterests,
        default: userProfile => userProfile.skills || [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(
                    yup.object().shape({
                        skill: yup
                            .string()
                            .oneOf(Skills.items)
                            .required()
                            .label('Skill'),
                        level: yup
                            .number()
                            .min(1)
                            .max(5)
                            .required()
                            .label('Experience level'),
                    }),
                )
                .max(10)
                .ensure()
                .label(FieldProps.skills.label)

            return required ? base.required() : base
        },
    },
    motivation: {
        ...FieldProps.motivation,
        category: Categories.skillsAndInterests,
        default: (userProfile, idToken) => '',
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(2000)
                .label(FieldProps.motivation.label)

            return required ? base.required() : base
        },
    },
    industriesOfInterest: {
        ...FieldProps.industriesOfInterest,
        category: Categories.skillsAndInterests,
        default: (userProfile, idToken) =>
            userProfile.industriesOfInterest || [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(yup.string().oneOf(Industries.industries))
                .max(3)
                .ensure()
                .label(FieldProps.industriesOfInterest.label)
            return required ? base.required() : base
        },
    },
    themesOfInterest: {
        ...FieldProps.themesOfInterest,
        category: Categories.skillsAndInterests,
        default: (userProfile, idToken) => userProfile.themesOfInterest || [],
        validationSchema: required => {
            const base = yup
                .array()
                .of(yup.string().oneOf(Themes.themes))
                .max(3)
                .ensure()
                .label(FieldProps.themesOfInterest.label)

            return required ? base.required() : base
        },
    },
    numHackathons: {
        ...FieldProps.numHackathons,
        category: Categories.skillsAndInterests,
        default: (userProfile, idToken) =>
            userProfile.numHackathons || undefined,
        validationSchema: required => {
            const base = yup
                .number()
                .min(0)
                .max(5)
                .label(FieldProps.numHackathons.label)

            return required ? base.required() : base
        },
    },
    education: {
        ...FieldProps.education,
        category: Categories.skillsAndInterests,
        default: (userProfile, idToken) => userProfile.education || undefined,
        validationSchema: required => {
            const base = yup
                .object()
                .shape({
                    level: required
                        ? yup.string().label('Level of Education').required()
                        : yup.string().label('Level of Education'),
                    university: yup.string().label('University'),
                    degree: yup.string().label('Degree'),
                    graduationYear: yup
                        .number()
                        .min(1900)
                        .max(2100)
                        .label('Graduation year'),
                })
                .noUnknown()
                .label(FieldProps.education.label)

            return required ? base.required() : base
        },
    },
    portfolio: {
        ...FieldProps.portfolio,
        category: Categories.links,
        default: (userProfile, idToken) => userProfile.portfolio || undefined,
        validationSchema: required => {
            const base = yup.string().url().label(FieldProps.portfolio.label)

            return required ? base.required() : base
        },
    },
    curriculumVitae: {
        ...FieldProps.curriculumVitae,
        category: Categories.links,
        default: (userProfile, idToken) =>
            userProfile.curriculumVitae || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .url()
                .label(FieldProps.curriculumVitae.label)

            return required ? base.required() : base
        },
    },
    github: {
        ...FieldProps.github,
        category: Categories.links,
        default: (userProfile, idToken) => userProfile.github || undefined,
        validationSchema: required => {
            const base = yup.string().url().label(FieldProps.github.label)

            return required ? base.required() : base
        },
    },
    linkedin: {
        ...FieldProps.linkedin,
        category: Categories.links,
        default: (userProfile, idToken) => userProfile.linkedin || undefined,
        validationSchema: required => {
            const base = yup.string().url().label(FieldProps.linkedin.label)

            return required ? base.required() : base
        },
    },
    countryOfTravel: {
        ...FieldProps.countryOfTravel,
        category: Categories.travelAndAccommodation,
        default: userProfile => userProfile.countryOfResidence || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .oneOf(Countries.asArrayOfName)
                .label(FieldProps.countryOfTravel.label)

            return required ? base.required() : base
        },
    },
    cityOfTravel: {
        ...FieldProps.cityOfTravel,
        category: Categories.travelAndAccommodation,
        default: userProfile => userProfile.cityOfResidence || undefined,
        validationSchema: required => {
            const base = yup
                .string()
                .min(required ? 1 : 0)
                .max(200)
                .label(FieldProps.cityOfTravel.label)

            return required ? base.required() : base
        },
    },
    needsVisa: {
        ...FieldProps.needsVisa,
        category: Categories.travelAndAccommodation,
        default: () => false,
        validationSchema: required => {
            const base = yup
                .boolean()
                .transform(value => {
                    if (!value) return false
                    return true
                })
                .label(FieldProps.needsVisa.label)

            return required ? base.required() : base
        },
    },
    needsTravelGrant: {
        ...FieldProps.needsTravelGrant,
        category: Categories.travelAndAccommodation,
        default: () => false,
        validationSchema: required => {
            const base = yup
                .boolean()
                .transform(value => {
                    if (!value) return false
                    return true
                })
                .label(FieldProps.needsTravelGrant.label)

            return required ? base.required() : base
        },
    },
    needsAccommodation: {
        ...FieldProps.needsAccommodation,
        category: Categories.travelAndAccommodation,
        default: () => false,
        validationSchema: required => {
            const base = yup
                .boolean()
                .transform(value => {
                    if (!value) return false
                    return true
                })
                .label(FieldProps.needsAccommodation.label)

            return required ? base.required() : base
        },
    },
    teamOptions: {
        ...FieldProps.teamOptions,
        category: Categories.other,
        default: () => ({
            applyAsTeam: false,
            applyAlone: false,
        }),
        validationSchema: required => {
            const base = yup
                .object()
                .shape({
                    applyAsTeam: yup
                        .boolean()
                        .transform(value => {
                            if (!value) return false
                            return true
                        })
                        .label('Applying as a team?'),
                    applyAlone: yup
                        .boolean()
                        .transform(value => {
                            if (!value) return false
                            return true
                        })
                        .label('Applying also alone?'),
                })
                .noUnknown()
                .label(FieldProps.teamOptions.label)

            return required ? base.required() : base
        },
    },
    secretCode: {
        ...FieldProps.secretCode,
        category: Categories.other,
        default: () => '',
        validationSchema: required => {
            const base = yup
                .string()
                .max(100)
                .label(FieldProps.secretCode.label)

            return required ? base.required() : base
        },
    },
    recruitmentOptions: {
        ...FieldProps.recruitmentOptions,
        category: Categories.recruitment,

        default: userProfile =>
            userProfile.recruitmentOptions || {
                consent: false,
            },
        validationSchema: required => {
            const base = yup
                .object()
                .shape({
                    status: yup.string(),
                    consent: yup.boolean().transform(value => {
                        if (!value) return false
                        return true
                    }),
                    relocation: yup.string(),
                })
                .noUnknown()
                .label(FieldProps.recruitmentOptions.label)

            return required ? base.required() : base
        },
    },
}
// TODO remove this since it kinds of prevents localization
function buildFieldToLabelMap() {
    const result = {}

    Object.keys(Fields).forEach(fieldName => {
        result[fieldName] = Fields[fieldName].label
    })

    return result
}

function buildFiltersArray() {
    const fields = Object.keys(Fields)
    const baseFilters = [
        {
            path: 'rating',
            label: 'Rating',
            type: FilterTypes.NUMBER,
            valueType: FilterValues.NUMBER,
        },
        {
            path: 'status',
            label: 'Status',
            type: FilterTypes.STRING,
            valueType: FilterValues.STATUS,
        },
        {
            path: 'tags',
            label: 'Tags',
            type: FilterTypes.ARRAY,
            valueType: FilterValues.TAG,
        },
        {
            path: 'travelGrant',
            label: 'Travel Grant',
            type: FilterTypes.NUMBER,
            valueType: FilterValues.NUMBER,
        },
    ]
    const answerFilters = fields.reduce((res, fieldKey) => {
        const field = Fields[fieldKey]
        if (!Array.isArray(field.filters) || !field.filters.length) return res
        const filters = field.filters.map(filter => {
            if (filter.path.length) {
                filter.path = `answers.${fieldKey}.${filter.path}`
            } else {
                filter.path = `answers.${fieldKey}`
            }
            return filter
        })
        return res.concat(filters)
    }, [])

    return baseFilters.concat(answerFilters)
}

const Helpers = {
    getCategoriesArray: () => {
        return _.sortBy(
            Object.keys(Categories).map(key => {
                return Categories[key]
            }),
            'order',
        )
    },
    getLabel: field => {
        if (Fields.hasOwnProperty(field)) {
            return Fields[field].label || field
        }
        return field
    },
    getFields: () => Fields,
    getField: field => Fields[field],
    getFieldType: field => (Fields[field] ? Fields[field].fieldType.id : null),
    filters: buildFiltersArray(),
    fieldToLabelMap: buildFieldToLabelMap(),
    fieldTypes: FieldTypes,
    getCategory: field => {
        if (Fields.hasOwnProperty(field)) {
            return Fields[field].category.label
        }
        return ''
    },
    getDefaultValue: (field, userProfile, idToken) => {
        if (Fields.hasOwnProperty(field)) {
            return Fields[field].default(userProfile, idToken)
        }
        return null
    },
    getDefaultValueCustom: question => {
        if (['boolean', 'checkbox'].indexOf(question.fieldType) !== -1) {
            return question.settings.default || false
        }
    },
    getDefaultValuesFromConfig: (
        config,
        customQuestions,
        userProfile,
        idToken,
    ) => {
        const result = {}

        Object.keys(config).forEach(field => {
            if (config[field].enable) {
                result[field] = Helpers.getDefaultValue(
                    field,
                    userProfile,
                    idToken,
                )
            }
        })

        customQuestions.forEach(customSection => {
            customSection.questions.forEach(question => {
                result[question.name] = Helpers.getDefaultValueCustom(question)
            })
        })

        return result
    },
    getDefaultValuesForFields: (fields, userProfile, idToken) => {
        const result = {}

        fields.forEach(field => {
            result[field] = Helpers.getDefaultValue(field, userProfile, idToken)
        })

        return result
    },
    getCategoryOrderByLabel: categoryLabel => {
        for (const categoryId of Object.keys(Categories)) {
            const category = Categories[categoryId]
            if (category.label === categoryLabel) {
                return category.order
            }
        }
        return 1000
    },
}

module.exports = Helpers
