import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import 'assets/it-purple-hack/css/cases.css'
import 'assets/it-purple-hack/css/prizes.css'
import 'assets/it-purple-hack/css/tilda-grid-3.0.min.css'
import 'assets/it-purple-hack/css/tilda-blocks-page41858678.min.css?t=1705305323'
import 'assets/it-purple-hack/css/tilda-animation-2.0.min.css'
import 'assets/it-purple-hack/css/tilda-popup-1.1.min.css'
import 'assets/it-purple-hack/css/tilda-slds-1.4.min.css'
import 'assets/it-purple-hack/css/tilda-catalog-1.1.min.css'
import 'assets/it-purple-hack/css/tilda-forms-1.0.min.css'
import 'assets/it-purple-hack/css/tilda-cards-1.0.min.css'
import 'assets/it-purple-hack/css/tilda-zoom-2.0.min.css'
import 'assets/it-purple-hack/css/fonts-tildasans.css'
import 'assets/it-purple-hack/css/other.css'

export default function ItPurpleHack({ hasRegistration }) {
    function t_onReady(func) {
        if (document.readyState != 'loading') {
            func()
        } else {
            document.addEventListener('DOMContentLoaded', func)
        }
    }
    function t_onFuncLoad(funcName, okFunc, time) {
        if (typeof window[funcName] === 'function') {
            okFunc()
        } else {
            setTimeout(function () {
                t_onFuncLoad(funcName, okFunc, time)
            }, time || 100)
        }
    }
    function t_throttle(fn, threshhold, scope) {
        return function () {
            fn.apply(scope || this, arguments)
        }
    }
    function t396_initialScale(t) {
        var e = document.getElementById('rec' + t)
        if (e) {
            var r = e.querySelector('.t396__artboard')
            if (r) {
                var a,
                    i = document.documentElement.clientWidth,
                    l = [],
                    d = r.getAttribute('data-artboard-screens')
                if (d) {
                    d = d.split(',')
                    for (var o = 0; o < d.length; o++) l[o] = parseInt(d[o], 10)
                } else l = [320, 480, 640, 960, 1200]
                for (o = 0; o < l.length; o++) {
                    var n = l[o]
                    n <= i && (a = n)
                }
                var g =
                        'edit' ===
                        window.allrecords.getAttribute('data-tilda-mode'),
                    u = 'center' === t396_getFieldValue(r, 'valign', a, l),
                    c = 'grid' === t396_getFieldValue(r, 'upscale', a, l),
                    t = t396_getFieldValue(r, 'height_vh', a, l),
                    f = t396_getFieldValue(r, 'height', a, l),
                    e =
                        (!!window.opr && !!window.opr.addons) ||
                        !!window.opera ||
                        -1 !== navigator.userAgent.indexOf(' OPR/')
                if (!g && u && !c && !t && f && !e) {
                    for (
                        var s = parseFloat((i / a).toFixed(3)),
                            _ = [
                                r,
                                r.querySelector('.t396__carrier'),
                                r.querySelector('.t396__filter'),
                            ],
                            o = 0;
                        o < _.length;
                        o++
                    )
                        _[o].style.height = parseInt(f, 10) * s + 'px'
                    for (
                        var h = r.querySelectorAll('.t396__elem'), o = 0;
                        o < h.length;
                        o++
                    )
                        h[o].style.zoom = s
                }
            }
        }
    }
    function t396_getFieldValue(t, e, r, a) {
        var i = a[a.length - 1],
            l =
                r === i
                    ? t.getAttribute('data-artboard-' + e)
                    : t.getAttribute('data-artboard-' + e + '-res-' + r)
        if (!l)
            for (var d = 0; d < a.length; d++) {
                var o = a[d]
                if (
                    !(o <= r) &&
                    (l =
                        o === i
                            ? t.getAttribute('data-artboard-' + e)
                            : t.getAttribute(
                                  'data-artboard-' + e + '-res-' + o,
                              ))
                )
                    break
            }
        return l
    }

    const loadScript = src => {
        const script = document.createElement('script')
        script.async = true
        script.src = `/it-purple-hack/${src}`
        document.body.append(script)
    }

    useEffect(() => {
        loadScript('js/tilda-scripts-3.0.min.js')
        loadScript('js/tilda-blocks-page41858678.min.js?t=1705305323')
        loadScript('js/tilda-lazyload-1.0.min.js')
        loadScript('js/tilda-animation-2.0.min.js')
        loadScript('js/tilda-zero-1.1.min.js')
        loadScript('js/tilda-t635-typed-1.0.min.js')
        loadScript('js/hammer.min.js')
        loadScript('js/tilda-slds-1.4.min.js')
        loadScript('js/tilda-products-1.0.min.js')
        loadScript('js/tilda-catalog-1.1.min.js')
        loadScript('js/tilda-cards-1.0.min.js')
        loadScript('js/tilda-menu-1.0.min.js')
        loadScript('js/tilda-submenublocks-1.0.min.js')
        loadScript('js/tilda-animation-ext-1.0.min.js')
        loadScript('js/tilda-animation-sbs-1.0.min.js')
        loadScript('js/tilda-zoom-2.0.min.js')
        loadScript('js/tilda-zero-scale-1.0.min.js')
        loadScript('js/tilda-skiplink-1.0.min.js')
        loadScript('js/tilda-events-1.0.min.js')

        t_onFuncLoad('t_menu__highlightActiveLinks', function () {
            window.t_menu__highlightActiveLinks('.t456__list_item a')
        })
        t_onFuncLoad('t_menu__findAnchorLinks', function () {
            window.t_menu__findAnchorLinks('677196653', '.t456__list_item a')
        })

        t_onFuncLoad('t_menu__setBGcolor', function () {
            window.t_menu__setBGcolor('677196653', '.t456')
            window.addEventListener('resize', function () {
                window.t_menu__setBGcolor('677196653', '.t456')
            })
        })
        t_onFuncLoad('t_menu__interactFromKeyboard', function () {
            window.t_menu__interactFromKeyboard('677196653')
        })

        t_onFuncLoad('t794_init', function () {
            window.t794_init('677196761')
        })

        t_onFuncLoad('t794_init', function () {
            window.t794_init('686346664')
        })

        t_onFuncLoad('t396_init', function () {
            window.t396_init('676592631')
        })

        t_onFuncLoad('t635_init', function () {
            window.t635_init('679615366', '#9495a3', '#9495a3')
        })

        t_onFuncLoad('t396_init', function () {
            window.t396_init('679616758')
        })

        t_onFuncLoad('t776__showMore', function () {
            window.t776__showMore('684396364')
        })
        t_onFuncLoad('t776__init', function () {
            window.t776__init('684396364')
        })

        t_onFuncLoad('t396_init', function () {
            window.t396_init('678565843')
        })

        t_onFuncLoad('t509_setHeight', function () {
            window.addEventListener(
                'resize',
                t_throttle(function () {
                    window.t509_setHeight('679667442')
                }),
            )
            window.t509_setHeight('679667442')
            var rec = document.querySelector('#rec679667442')
            if (!rec) return
            var wrapper = rec.querySelector('.t509')
            if (wrapper) {
                wrapper.addEventListener('displayChanged', function () {
                    window.t509_setHeight('679667442')
                })
            }
        })

        t_onFuncLoad('t396_init', function () {
            window.t396_init('677194115')
        })

        t_onFuncLoad('t635_init', function () {
            window.t635_init('676592662', '#9495a3', '#9495a3')
        })

        t_onFuncLoad('t890_init', function () {
            window.t890_init('676592663', '')
        })
    }, [])

    return (
        <>
            <Helmet
                link={[
                    {
                        rel: 'icon',
                        type: 'image/x-icon',
                        href: '/it-purple-hack/favicon.ico',
                    },
                ]}
                title="IT Purple Hack"
            />
            <Helmet>
                <title>IT Purple Hack</title>
                <meta
                    name="keywords"
                    content="Хакатон, Олайн хакатон, IT Purple, Соревнования"
                />
                <meta name="title" content={'IT Purple Hack'} />
                <meta property="og:title" content={'IT Purple Hack'} />

                <meta name="twitter:title" content={'IT Purple Hack'} />
                <meta
                    name="description"
                    content="IT Purple Hack — это онлайн-хакатон, который проводится ФПМИ совместно с Тинькофф, Сбербанк, Альфа Банк, Sber Tech, Авито и Банк России."
                />
                <meta
                    property="og:description"
                    content="IT Purple Hack — это онлайн-хакатон, который проводится ФПМИ совместно с Тинькофф, Сбербанк, Альфа Банк, Sber Tech, Авито и Банк России."
                />
                <meta
                    name="twitter:description"
                    content="IT Purple Hack — это онлайн-хакатон, который проводится ФПМИ совместно с Тинькофф, Сбербанк, Альфа Банк, Sber Tech, Авито и Банк России."
                />

                <meta name="og:type" content="website" />
                <meta
                    property="og:image"
                    content="/it-purple-hack/images/tild3062-3937-4839-a139-303763663337__group_42.png"
                />
                <meta
                    name="twitter:image"
                    content="/it-purple-hack/images/tild3062-3937-4839-a139-303763663337__group_42.png"
                />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                id="allrecords"
                data-tilda-export="yes"
                className="t-records"
                data-hook="blocks-collection-content-node"
                data-tilda-project-id="7874881"
                data-tilda-page-id="41858678"
                data-tilda-page-alias="hack"
                data-tilda-formskey="4ac752927a2ea1d6702ed57387874881"
                data-tilda-cookie="no"
                data-tilda-lazy="yes"
                style={{ overflowX: 'hidden' }}
            >
                <header
                    id="t-header"
                    className="t-records"
                    data-hook="blocks-collection-content-node"
                    data-tilda-project-id="7874881"
                    data-tilda-page-id="41895764"
                    data-tilda-formskey="4ac752927a2ea1d6702ed57387874881"
                    data-tilda-cookie="no"
                    data-tilda-lazy="yes"
                >
                    <div
                        id="rec677196653"
                        className="r t-rec t-rec_pt_0"
                        style={{
                            paddingTop: '0px',
                            backgroundColor: '#101011',
                        }}
                        data-animationappear="off"
                        data-record-type="456"
                        data-bg-color="#101011"
                    >
                        <div id="nav677196653marker"></div>
                        <div
                            id="nav677196653"
                            className="t456 t456__positionfixed"
                            style={{ backgroundColor: 'rgba(11, 11, 11, 1)' }}
                            data-bgcolor-hex="#0b0b0b"
                            data-bgcolor-rgba="rgba(11,11,11,1)"
                            data-navmarker="nav677196653marker"
                            data-appearoffset=""
                            data-bgopacity-two=""
                            data-menushadow=""
                            data-bgopacity="1"
                            data-menu-items-align="right"
                            data-menu="yes"
                        >
                            <div className="t456__maincontainer t456__c12collumns">
                                <div
                                    className="t456__leftwrapper"
                                    style={{
                                        paddingLeft: '20px',
                                        minWidth: '90px',
                                        width: '90px',
                                    }}
                                >
                                    <div
                                        className="t456__logowrapper"
                                        style={{ display: 'block' }}
                                    >
                                        <img
                                            className="t456__imglogo t456__imglogomobile"
                                            src="/it-purple-hack/images/tild3366-3331-4465-b265-323834313437__logo_fpmi_white.png"
                                            imgfield="img"
                                            style={{
                                                maxWidth: '90px',
                                                width: '90px',
                                            }}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <nav
                                    className="t456__rightwrapper t456__menualign_right"
                                    style={{ paddingRight: '20px' }}
                                >
                                    <ul
                                        role="list"
                                        className="t456__list t-menu__list"
                                    >
                                        <li
                                            className="t456__list_item"
                                            style={{ padding: '0 15px 0 0' }}
                                        >
                                            <a
                                                className="t-menu__link-item"
                                                href="#prizes"
                                                data-menu-submenu-hook=""
                                                data-menu-item-number="1"
                                                style={{
                                                    color: '#ffffff',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Призы
                                            </a>
                                        </li>
                                        <li
                                            className="t456__list_item"
                                            style={{ padding: '0 15px' }}
                                        >
                                            <a
                                                className="t-menu__link-item"
                                                href="#rec679668758"
                                                data-menu-submenu-hook=""
                                                data-menu-item-number="2"
                                                style={{
                                                    color: '#ffffff',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Кейсы
                                            </a>
                                        </li>
                                        <li
                                            className="t456__list_item"
                                            style={{ padding: '0 15px' }}
                                        >
                                            <a
                                                className="t-menu__link-item"
                                                href="#rec684399771"
                                                data-menu-submenu-hook=""
                                                data-menu-item-number="3"
                                                style={{
                                                    color: '#ffffff',
                                                    fontWeight: 600,
                                                }}
                                            >
                                                Даты проведения
                                            </a>
                                        </li>
                                        <li
                                            className="t456__list_item"
                                            style={{ padding: '0 15px' }}
                                        >
                                            {hasRegistration || true ? (
                                                <Link
                                                    to="/dashboard/it-purple-hack"
                                                    className="t-menu__link-item"
                                                    data-menu-submenu-hook=""
                                                    data-menu-item-number="4"
                                                    style={{
                                                        color: '#ffffff',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Войти
                                                </Link>
                                            ) : (
                                                <Link
                                                    className="t-menu__link-item"
                                                    to="/events/it-purple-hack"
                                                    data-menu-submenu-hook=""
                                                    data-menu-item-number="4"
                                                    style={{
                                                        color: '#ffffff',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    Войти
                                                </Link>
                                            )}
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div
                        id="rec686346664"
                        className="r t-rec"
                        data-animationappear="off"
                        data-record-type="794"
                    >
                        <div
                            className="t794"
                            data-tooltip-hook="#submenu:registration2"
                            data-tooltip-margin="15px"
                            data-add-arrow="on"
                        >
                            <div className="t794__tooltip-menu">
                                <div className="t794__tooltip-menu-corner"></div>
                                <div className="t794__tooltip-menu-corner-border"></div>
                                <div className="t794__content">
                                    <ul
                                        className="t794__list"
                                        role="menu"
                                        aria-label=""
                                    >
                                        <li className="t794__list_item t-submenublocks__item t-name t-name_xs">
                                            <a
                                                className="t794__typo t794__link t-name t-name_xs"
                                                role="menuitem"
                                                href="http://fpmiconf.ru/confreg"
                                                style={{
                                                    color: '#ffffff',
                                                    fontSize: '18px',
                                                    fontWeight: 400,
                                                    fontFamily: 'Arial',
                                                }}
                                                target="_blank"
                                                data-menu-item-number="1"
                                            >
                                                Регистрация на конференцию
                                            </a>
                                        </li>
                                        <li className="t794__list_item t-submenublocks__item t-name t-name_xs">
                                            <a
                                                className="t794__typo t794__link t-name t-name_xs"
                                                role="menuitem"
                                                href="http://fpmiconf.ru/hackreg"
                                                style={{
                                                    color: '#ffffff',
                                                    fontSize: '18px',
                                                    fontWeight: 400,
                                                    fontFamily: 'Arial',
                                                }}
                                                target="_blank"
                                                data-menu-item-number="2"
                                            >
                                                Регистрация на хакатон
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div
                    id="rec676592631"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="396"
                >
                    <div className="t396">
                        <div
                            className="t396__artboard"
                            data-artboard-recid="676592631"
                            data-artboard-screens="320,480,640,960,1200"
                            data-artboard-height="1000"
                            data-artboard-valign="center"
                            data-artboard-upscale="grid"
                            data-artboard-height-res-320="1030"
                            data-artboard-height-res-480="1000"
                            data-artboard-height-res-640="1200"
                            data-artboard-height-res-960="1340"
                            style={{ overflow: 'visible' }}
                        >
                            <div
                                className="t396__carrier"
                                data-artboard-recid="676592631"
                            ></div>
                            <div
                                className="t396__filter"
                                data-artboard-recid="676592631"
                            ></div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311700416371269"
                                data-elem-id="1700416371269"
                                data-elem-type="image"
                                data-field-top-value="-920"
                                data-field-left-value="-473"
                                data-field-width-value="3100"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-filewidth-value="1680"
                                data-field-fileheight-value="1500"
                                data-field-top-res-320-value="-1010"
                                data-field-left-res-320-value="-1034"
                                data-field-top-res-480-value="-1070"
                                data-field-left-res-480-value="-913"
                                data-field-top-res-640-value="-1070"
                                data-field-left-res-640-value="-913"
                                data-field-top-res-960-value="-920"
                                data-field-left-res-960-value="-633"
                            >
                                <div className="tn-atom">
                                    <img
                                        className="tn-atom__img t-img"
                                        data-original="/it-purple-hack/images/tild3062-3937-4839-a139-303763663337__group_42.png"
                                        alt=""
                                        imgfield="tn_img_1700416371269"
                                    />
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311700386902451"
                                data-elem-id="1700386902451"
                                data-elem-type="text"
                                data-field-top-value="293"
                                data-field-left-value="21"
                                data-field-width-value="536"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="234"
                                data-field-left-res-320-value="21"
                                data-field-width-res-480-value="285"
                                data-field-top-res-640-value="230"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1700386902451"
                                >
                                    IT Purple Hack
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311701332657958"
                                data-elem-id="1701332657958"
                                data-elem-type="text"
                                data-field-top-value="430"
                                data-field-left-value="244"
                                data-field-width-value="488"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="272"
                                data-field-left-res-320-value="21"
                                data-field-width-res-320-value="281"
                                data-field-top-res-480-value="290"
                                data-field-left-res-480-value="156"
                                data-field-top-res-640-value="320"
                                data-field-left-res-640-value="201"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701332657958"
                                >
                                    9-15 march 2024;
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311700389780684"
                                data-elem-id="1700389780684"
                                data-elem-type="button"
                                data-field-top-value="617"
                                data-field-left-value="20"
                                data-field-height-value="68"
                                data-field-width-value="323"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="389"
                                data-field-left-res-320-value="21"
                                data-field-height-res-320-value="55"
                                data-field-width-res-320-value="284"
                                data-field-top-res-480-value="425"
                                data-field-left-res-480-value="21"
                                data-field-width-res-480-value="453"
                                data-field-top-res-640-value="487"
                                data-field-left-res-640-value="18"
                                data-field-width-res-640-value="410"
                            >
                                {hasRegistration ? (
                                    <Link
                                        className="tn-atom"
                                        to="/dashboard/it-purple-hack"
                                    >
                                        Войти
                                    </Link>
                                ) : (
                                    <span>Регистрация окончена</span>
                                    // <Link
                                    //     className="tn-atom"
                                    //     to="/events/it-purple-hack/register"
                                    // >
                                    //     Зарегистрироваться
                                    // </Link>
                                )}
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311701705636103"
                                data-elem-id="1701705636103"
                                data-elem-type="text"
                                data-field-top-value="509"
                                data-field-left-value="21"
                                data-field-width-value="618"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="319"
                                data-field-left-res-320-value="21"
                                data-field-width-res-320-value="290"
                                data-field-top-res-480-value="343"
                                data-field-left-res-480-value="21"
                                data-field-width-res-480-value="401"
                                data-field-top-res-640-value="392"
                                data-field-left-res-640-value="20"
                                data-field-width-res-640-value="476"
                                data-field-left-res-960-value="21"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701705636103"
                                >
                                    - 6 кейсов от ведущих IT компаний
                                    <br />- Призовой фонд 1.000.000 ₽
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311701706291029"
                                data-elem-id="1701706291029"
                                data-elem-type="text"
                                data-field-top-value="251"
                                data-field-left-value="23"
                                data-field-width-value="618"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="192"
                                data-field-left-res-320-value="21"
                                data-field-width-res-320-value="299"
                                data-field-top-res-480-value="192"
                                data-field-left-res-480-value="21"
                                data-field-width-res-480-value="459"
                                data-field-top-res-640-value="189"
                                data-field-left-res-640-value="21"
                                data-field-width-res-640-value="520"
                                data-field-left-res-960-value="21"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701706291029"
                                >
                                    Онлайн Хакатон / |
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6765926311700389706823"
                                data-elem-id="1700389706823"
                                data-elem-type="image"
                                data-field-top-value="275"
                                data-field-left-value="814"
                                data-field-width-value="250"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-animate-prx="mouse"
                                data-animate-prx-dx="20"
                                data-animate-prx-dy="20"
                                data-field-filewidth-value="1408"
                                data-field-fileheight-value="1150"
                                data-field-top-res-320-value="184"
                                data-field-left-res-320-value="245"
                                data-field-width-res-320-value="61"
                                data-field-top-res-480-value="197"
                                data-field-left-res-480-value="321"
                                data-field-width-res-480-value="110"
                                data-field-top-res-640-value="189"
                                data-field-left-res-640-value="410"
                                data-field-width-res-640-value="146"
                                data-field-left-res-960-value="654"
                            >
                                <div className="tn-atom">
                                    <img
                                        className="tn-atom__img t-img"
                                        data-original="/it-purple-hack/images/tild3964-3265-4933-b033-623938373133_____it_purple.png"
                                        alt=""
                                        imgfield="tn_img_1700389706823"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="rec679615366"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="635"
                >
                    <div
                        className="t635__textholder"
                        data-recid="676592631"
                        data-text1="Онлайн"
                        data-text2="Для студентов"
                        data-text3="Бесплатно"
                    ></div>
                </div>

                <div className="prizes" id="prizes">
                    <div className="prizes__container">
                        <p className="prizes__title">Призы</p>
                        <div className="prizes__content">
                            <div className="prizes__list">
                                <div className="prizes__prize">
                                    <div className="prizes__prize-top">
                                        <p className="prizes__prize-place-number">
                                            1
                                        </p>
                                        <p className="prizes__prize-place-label">
                                            место
                                        </p>
                                    </div>
                                    <div className="prizes__prize-bottom">
                                        <p className="prizes__prize-amount-number">
                                            100 000
                                        </p>
                                        <p className="prizes__prize-amount-label">
                                            руб
                                        </p>
                                    </div>
                                </div>
                                <div className="prizes__prize">
                                    <div className="prizes__prize-top">
                                        <p className="prizes__prize-place-number">
                                            2
                                        </p>
                                        <p className="prizes__prize-place-label">
                                            место
                                        </p>
                                    </div>
                                    <div className="prizes__prize-bottom">
                                        <p className="prizes__prize-amount-number">
                                            50 000
                                        </p>
                                        <p className="prizes__prize-amount-label">
                                            руб
                                        </p>
                                    </div>
                                </div>
                                <div className="prizes__prize">
                                    <div className="prizes__prize-top">
                                        <p className="prizes__prize-place-number">
                                            3
                                        </p>
                                        <p className="prizes__prize-place-label">
                                            место
                                        </p>
                                    </div>
                                    <div className="prizes__prize-bottom">
                                        <p className="prizes__prize-amount-number">
                                            25 000
                                        </p>
                                        <p className="prizes__prize-amount-label">
                                            руб
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="prizes__card">
                                <p className="prizes__card-text">
                                    Все победители и призеры получат мерч,
                                    привилегии от компаний и билет на второй VIP
                                    день
                                </p>
                                <div className="prizes__card-images">
                                    <img
                                        className="prizes__card-img"
                                        src="/it-purple-hack/images/tild6331-6164-4163-b364-313265366534__ticket_3.png"
                                    />
                                    <img
                                        className="prizes__card-img"
                                        src="/it-purple-hack/images/tild6331-6164-4163-b364-313265366534__ticket_3.png"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec679616758"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="396"
                >
                    <div className="t396">
                        <div
                            className="t396__artboard"
                            data-artboard-recid="679616758"
                            data-artboard-screens="320,480,640,960,1200"
                            data-artboard-height="630"
                            data-artboard-valign="center"
                            data-artboard-upscale="grid"
                            data-artboard-height-res-320="720"
                            data-artboard-height-res-480="680"
                            data-artboard-height-res-640="780"
                            data-artboard-height-res-960="500"
                            style={{ overflow: 'visible' }}
                        >
                            <div
                                className="t396__carrier"
                                data-artboard-recid="679616758"
                            ></div>
                            <div
                                className="t396__filter"
                                data-artboard-recid="679616758"
                            ></div>
                            <div
                                className="t396__elem tn-elem tn-elem__6796167581701191038752"
                                data-elem-id="1701191038752"
                                data-elem-type="shape"
                                data-field-top-value="169"
                                data-field-left-value="-100"
                                data-field-height-value="134"
                                data-field-width-value="290"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="69"
                                data-field-left-res-320-value="-74"
                                data-field-top-res-480-value="69"
                                data-field-left-res-480-value="-87"
                                data-field-top-res-640-value="75"
                                data-field-left-res-640-value="72"
                                data-field-top-res-960-value="136"
                                data-field-left-res-960-value="-94"
                            >
                                <div className="tn-atom"></div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6796167581701174292948"
                                data-elem-id="1701174292948"
                                data-elem-type="text"
                                data-field-top-value="111"
                                data-field-left-value="412"
                                data-field-width-value="687"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="291"
                                data-field-left-res-320-value="23"
                                data-field-width-res-320-value="301"
                                data-field-top-res-480-value="335"
                                data-field-left-res-480-value="26"
                                data-field-width-res-480-value="461"
                                data-field-top-res-640-value="364"
                                data-field-left-res-640-value="28"
                                data-field-top-res-960-value="121"
                                data-field-left-res-960-value="331"
                                data-field-width-res-960-value="568"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701174292948"
                                >
                                    IT Purple Hack
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6796167581701179358211"
                                data-elem-id="1701179358211"
                                data-elem-type="text"
                                data-field-top-value="201"
                                data-field-left-value="412"
                                data-field-width-value="769"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="328"
                                data-field-left-res-320-value="23"
                                data-field-width-res-320-value="289"
                                data-field-top-res-480-value="379"
                                data-field-left-res-480-value="26"
                                data-field-width-res-480-value="459"
                                data-field-top-res-640-value="421"
                                data-field-left-res-640-value="28"
                                data-field-width-res-640-value="588"
                                data-field-top-res-960-value="178"
                                data-field-left-res-960-value="331"
                                data-field-width-res-960-value="620"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701179358211"
                                >
                                    Участники хакатона получат возможность
                                    решить кейсы крупнейших IT компаний России,
                                    связанные с созданием цифровых продуктов и
                                    оптимизацией бизнес-процессов
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6796167581701185018302"
                                data-elem-id="1701185018302"
                                data-elem-type="text"
                                data-field-top-value="201"
                                data-field-left-value="20"
                                data-field-width-value="370"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="111"
                                data-field-left-res-320-value="23"
                                data-field-width-res-320-value="299"
                                data-field-top-res-480-value="151"
                                data-field-left-res-480-value="26"
                                data-field-width-res-480-value="338"
                                data-field-top-res-640-value="125"
                                data-field-left-res-640-value="230"
                                data-field-width-res-640-value="368"
                                data-field-top-res-960-value="178"
                                data-field-left-res-960-value="10"
                                data-field-width-res-960-value="375"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701185018302"
                                >
                                    Хакатон
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6796167581701170774125"
                                data-elem-id="1701170774125"
                                data-elem-type="text"
                                data-field-top-value="441"
                                data-field-left-value="20"
                                data-field-width-value="395"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="147"
                                data-field-left-res-320-value="23"
                                data-field-width-res-320-value="291"
                                data-field-top-res-480-value="151"
                                data-field-left-res-480-value="128"
                                data-field-width-res-480-value="341"
                                data-field-top-res-640-value="180"
                                data-field-left-res-640-value="230"
                                data-field-width-res-640-value="403"
                                data-field-top-res-960-value="334"
                                data-field-left-res-960-value="10"
                                data-field-width-res-960-value="300"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701170774125"
                                >
                                    — это площадка для развития профессиональных
                                    навыков, обмена опытом и создания
                                    инновационных решений
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec684399771"
                    className="r t-rec t-rec_pt_120 t-rec_pb_120"
                    style={{
                        paddingTop: '120px',
                        paddingBottom: '120px',
                        backgroundColor: '#101011',
                    }}
                    data-record-type="1106"
                    data-bg-color="#101011"
                >
                    <div
                        className="t1106"
                        style={{ position: 'relative', zIndex: 11 }}
                    >
                        <div className="t-section__container t-container">
                            <div className="t-col t-col_12">
                                <div className="t-section__topwrapper t-align_left">
                                    <div
                                        className="t-section__title t-title t-title_xs"
                                        field="btitle"
                                    >
                                        <span
                                            style={{
                                                color: 'rgb(255, 255, 255)',
                                            }}
                                        >
                                            Даты проведения
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="t-container">
                            <div className="t1106__col t-item t-col t-col_3">
                                <div className="t1106__numberwrapper">
                                    <div className="t1106__number">
                                        <div className="t1106__digit t-name t-name_xs">
                                            1
                                        </div>
                                    </div>
                                    <div className="t1106__line"></div>
                                    <div className="t1106__line_mobile"></div>
                                </div>
                                <div className="t1106__textwrapper t-align_left">
                                    <div
                                        className="t1106__title t-name t-name_md"
                                        field="li_title__1702900012848"
                                    >
                                        Регистрация
                                    </div>
                                    <div
                                        className="t1106__text t-text t-text_xs"
                                        field="li_descr__1702900012848"
                                    >
                                        Прием заявок на хакатон до 05.03.24
                                    </div>
                                </div>
                            </div>
                            <div className="t1106__col t-item t-col t-col_2">
                                <div className="t1106__numberwrapper">
                                    <div className="t1106__number">
                                        <div className="t1106__digit t-name t-name_xs">
                                            2
                                        </div>
                                    </div>
                                    <div className="t1106__line"></div>
                                    <div className="t1106__line_mobile"></div>
                                </div>
                                <div className="t1106__textwrapper t-align_left">
                                    <div
                                        className="t1106__title t-name t-name_md"
                                        field="li_title__1702900021385"
                                    >
                                        Решение
                                    </div>
                                    <div
                                        className="t1106__text t-text t-text_xs"
                                        field="li_descr__1702900021385"
                                    >
                                        Кейсы открываются 09 марта в 10:00
                                    </div>
                                </div>
                            </div>
                            <div className="t1106__col t-item t-col t-col_2">
                                <div className="t1106__numberwrapper">
                                    <div className="t1106__number">
                                        <div className="t1106__digit t-name t-name_xs">
                                            3
                                        </div>
                                    </div>
                                    <div className="t1106__line"></div>
                                    <div className="t1106__line_mobile"></div>
                                </div>
                                <div className="t1106__textwrapper t-align_left">
                                    <div
                                        className="t1106__title t-name t-name_md"
                                        field="li_title__1702900047534"
                                    >
                                        Стоп-кодинг
                                    </div>
                                    <div
                                        className="t1106__text t-text t-text_xs"
                                        field="li_descr__1702900047534"
                                    >
                                        Работы принимаются до 10:00 14 марта
                                    </div>
                                </div>
                            </div>
                            <div className="t1106__col t-item t-col t-col_2">
                                <div className="t1106__numberwrapper">
                                    <div className="t1106__number">
                                        <div className="t1106__digit t-name t-name_xs">
                                            4
                                        </div>
                                    </div>
                                    <div className="t1106__line"></div>
                                    <div className="t1106__line_mobile"></div>
                                </div>
                                <div className="t1106__textwrapper t-align_left">
                                    <div
                                        className="t1106__title t-name t-name_md"
                                        field="li_title__1702900047534"
                                    >
                                        Защита
                                    </div>
                                    <div
                                        className="t1106__text t-text t-text_xs"
                                        field="li_descr__1702900047534"
                                    >
                                        Защита решений пройдет 15 марта в онлайн
                                        формате
                                    </div>
                                </div>
                            </div>
                            <div className="t1106__col t-item t-col t-col_3">
                                <div className="t1106__numberwrapper">
                                    <div className="t1106__number">
                                        <div className="t1106__digit t-name t-name_xs">
                                            5
                                        </div>
                                    </div>
                                    <div className="t1106__line"></div>
                                    <div className="t1106__line_mobile"></div>
                                </div>
                                <div className="t1106__textwrapper t-align_left">
                                    <div
                                        className="t1106__title t-name t-name_md"
                                        field="li_title__1702900056157"
                                    >
                                        Награждение
                                    </div>
                                    <div
                                        className="t1106__text t-text t-text_xs"
                                        field="li_descr__1702900056157"
                                    >
                                        Церемония награждения состоится в онлайн
                                        формате 15 марта в 18:00
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec679668758"
                    className="r t-rec t-rec_pt_90 t-rec_pb_30"
                    style={{
                        paddingTop: '90px',
                        paddingBottom: '30px',
                        backgroundColor: '#101011',
                    }}
                    data-record-type="128"
                    data-bg-color="#101011"
                >
                    <div className="t120">
                        <div className="t-container t-align_left">
                            <div className="t-col t-col_12">
                                <div
                                    className="t120__title t-heading t-heading_sm"
                                    field="title"
                                >
                                    Кейсы
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="rec684396364"
                    className="r t-rec t-rec_pt_0 t-rec_pb_150"
                    style={{
                        paddingTop: '0px',
                        paddingBottom: '150px',
                        backgroundColor: '#101011',
                    }}
                    data-animationappear="off"
                    data-record-type="776"
                    data-bg-color="#101011"
                >
                    <div className="cases">
                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/alpha.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">
                                        Искусственный интеллект
                                    </p>
                                    <p className="case__description">
                                        Решение бизнес-задач, связанных с CLTV
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/alpha.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Искусственный интеллект
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            Решение бизнес-задач, связанных с
                                            CLTV
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Модель CLTV позволяет определять
                                            ценность клиента для Банка на всем
                                            сроке его жизни. В качестве
                                            показателя ценности клиента мы
                                            используем операционную прибыль -
                                            сумму всех доходных и расходных
                                            операций. Нам известно, что основной
                                            характеристикой клиента, влияющей на
                                            его прибыльность, является набор
                                            продуктов (или продуктовый кластер),
                                            которыми клиент активно пользуется,
                                            то есть генерирует операционную
                                            прибыль. Мы предлагаем вам построить
                                            модель, которая будет предсказывать
                                            продуктовый кластер клиента -
                                            Юридического лица. Участникам будут
                                            доступны табличные данные об
                                            основных характеристиках компании и
                                            транзакционной активности, а также
                                            информация о текущем кластере
                                            клиента.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/tinkoff.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">AR, GameDev</p>
                                    <p className="case__description">
                                        AR технологии для привлечения в
                                        банковские продукты
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/tinkoff.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            AR, GameDev
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            AR технологии для привлечения в
                                            банковские продукты
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Стандартные банковские продукты не
                                            привлекают достаточное внимание и
                                            интерес у молодой аудитории,
                                            особенно несовершеннолетних
                                            пользователей. Это приводит к
                                            недостаточной финансовой
                                            грамотности, уязвимости к мошенникам
                                            и спонтанным тратам, а также
                                            ограничивает возможности
                                            использования и продвижения
                                            банковских услуг. Необходимо
                                            разработать виральную игровую
                                            механику на базе технологии
                                            дополненной реальности, которая бы
                                            привлекла детскую аудиторию в
                                            банковские продукты Джуниор и
                                            помогла им понять и развить
                                            финансовые навыки.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/hh.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">
                                        Machine learning
                                    </p>
                                    <p className="case__description">
                                        Skill-based подход в резюме
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/hh.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Machine learning
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            Skill-based подход в резюме
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Как подобрать идеального кандидата
                                            на вакансию? Очень сложно из 100+
                                            откликов отобрать лучшего
                                            соискателя. Поэтому мы в hh
                                            постепенно переходим на skill-based
                                            концепцию. Таким образом мы можем
                                            находить пары (мэтчить) соискателя и
                                            вакансию: предлагать идеального
                                            кандидата работодателю и,
                                            аналогично, предлагать идеальную для
                                            кандидата вакансию. Сейчас
                                            skill-based подход проник во многие
                                            сферы работы hh.ru, но ключевой
                                            проблемой данного подхода - это
                                            недостаток "дифференцирующих", т.е.
                                            отличающих кандидатов/вакансии друг
                                            от друга навыков в резюме и
                                            вакансиях. Одна из основных задач
                                            Data Scientist'ов в hh - увеличение
                                            "дифференцирующих" навыков, путем
                                            рекомендации их
                                            соискателю/работодателю при
                                            составлении резюме/вакансии. Нам
                                            хотелось бы улучшить рекомендации
                                            навыков научившись извлекать навыки
                                            прямиком из резюме соискателей.
                                        </p>
                                    </div>
                                    <Link
                                        to="/events/it-purple-hack/register"
                                        className="case__btn"
                                        onClick={e =>
                                            e.currentTarget.parentElement.parentElement.classList.remove(
                                                'active',
                                            )
                                        }
                                    >
                                        Зарегистрироваться
                                    </Link>
                                </div>
                            </div>
                        </div> */}

                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/avito.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">Разработка</p>
                                    <p className="case__description">
                                        Платформа ценообразования Авито
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/avito.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Разработка
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            Платформа ценообразования Авито
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            На платформе Авито существует целый
                                            кластер, занимающийся оптимизацией
                                            цен на платные продукты. Это
                                            включает в себя разовые размещения,
                                            тарифы для частных пользователей,
                                            тарифы+ для коммерческих
                                            пользователей и различные услуги
                                            продвижения. Ежедневно растет трафик
                                            на сервисы монетизации, при этом мы
                                            должны по-прежнему соблюдать SLO
                                            99,99. Необходимо разработать админ
                                            панель, которая предназначена для
                                            аналитиков и позволяет
                                            устанавливать, изменять и удалять
                                            цены для узлов дерева локаций и
                                            категорий.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/bank.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">
                                        Искусственный интеллект
                                    </p>
                                    <p className="case__description">
                                        Разработать систему, отвечающую на
                                        вопросы пользователей на естественном
                                        языке
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/bank.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Искусственный интеллект
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            Разработать систему, отвечающую на
                                            вопросы пользователей на
                                            естественном языке
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Пользователи часто задают самые
                                            разные вопросы о Банке России, от
                                            общего описания работы, до деталей
                                            нормативных актах Банка. Вопросы
                                            задаются естественным языком в
                                            свободной форме и часто не содержат
                                            ключевых слов по которым должен
                                            осуществляться поиск. Такие вопросы
                                            приходится интерпретировать и
                                            собрать информацию из нескольких
                                            мест для наиболее полного и
                                            развернутого ответа. Необходимо
                                            делегировать интерпретацию вопроса
                                            пользователя и поиск информации ИИ.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/sber.png"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">
                                        Искусственный интеллект
                                    </p>
                                    <p className="case__description">
                                        Прогнозирование оттока зарплатного
                                        клиента ФЛ
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/sber.png"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Искусственный интеллект
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            Прогнозирование оттока зарплатного
                                            клиента ФЛ
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Клиент получает зарплату на карту
                                            банка A. Пока клиент получает
                                            зарплату в банке, он считается
                                            зарплатным клиентом банка A. В
                                            какой-то момент Х он перестает
                                            получать зарплату на карту банка A
                                            (событие оттока). Необходимо до
                                            возникновения события оттока
                                            спрогнозировать его, используя
                                            данные поведения клиента:
                                            транзакции, продукты, мобильное
                                            приложение, терминалы, прочее.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="case">
                            <div className="case__main">
                                <img
                                    className="case__logo"
                                    src="/it-purple-hack/images/sbertech.svg"
                                />
                                <img
                                    className="case__separator"
                                    src="/it-purple-hack/images/pluses.png"
                                />
                                <div className="case__text">
                                    <p className="case__name">Разработка</p>
                                    <p className="case__description">
                                        При планировании проекта в целях
                                        оптимизации его длительности или
                                        стоимости необходимо построить
                                        оптимальный календарный план с учетом
                                        ограничений по времени и ресурсам.
                                    </p>
                                </div>
                            </div>
                            <p
                                className="case__btn"
                                onClick={e =>
                                    e.currentTarget.nextElementSibling.classList.add(
                                        'active',
                                    )
                                }
                            >
                                Подробнее
                            </p>
                            <div className="modal">
                                <span
                                    className="modal__overlay"
                                    onClick={e =>
                                        e.currentTarget.parentElement.classList.remove(
                                            'active',
                                        )
                                    }
                                ></span>
                                <div className="modal__content">
                                    <div className="modal__header">
                                        <img
                                            className="modal__logo"
                                            src="/it-purple-hack/images/sbertech.svg"
                                        />
                                        <img
                                            className="modal__close"
                                            src="/it-purple-hack/images/times.png"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Трек
                                        </p>
                                        <p className="modal__block-description">
                                            Разработка
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Задача
                                        </p>
                                        <p className="modal__block-description">
                                            При планировании проекта в целях
                                            оптимизации его длительности или
                                            стоимости необходимо построить
                                            оптимальный календарный план с
                                            учетом ограничений по времени и
                                            ресурсам.
                                        </p>
                                    </div>
                                    <div className="modal__block">
                                        <p className="modal__block-title">
                                            Описание задачи
                                        </p>
                                        <p className="modal__block-description">
                                            Необходимо написать алгоритм,
                                            который за счет изменения
                                            последовательности выполнения задач
                                            и изменения назначенных исполнителей
                                            должен уметь минимизировать
                                            определенные параметры плана
                                            проекта. Решение проблемы позволит
                                            оптимизировать затраты проекта и
                                            загрузку команд на проектах, а также
                                            снизить трудозатраты при
                                            планировании проекта.
                                        </p>
                                    </div>
                                    {hasRegistration ? (
                                        <Link
                                            to="/dashboard/it-purple-hack"
                                            className="case__btn"
                                            onClick={e =>
                                                e.currentTarget.parentElement.parentElement.classList.remove(
                                                    'active',
                                                )
                                            }
                                        >
                                            Войти
                                        </Link>
                                    ) : (
                                        <span>Регистрация окончена</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="rec678565843"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="396"
                >
                    <div className="t396">
                        <div
                            className="t396__artboard"
                            data-artboard-recid="678565843"
                            data-artboard-screens="320,480,640,960,1200"
                            data-artboard-height="580"
                            data-artboard-valign="center"
                            data-artboard-upscale="grid"
                            data-artboard-height-res-320="850"
                            data-artboard-height-res-480="550"
                            data-artboard-height-res-640="700"
                            data-artboard-height-res-960="550"
                        >
                            <div
                                className="t396__carrier"
                                data-artboard-recid="678565843"
                            ></div>
                            <div
                                className="t396__filter"
                                data-artboard-recid="678565843"
                            ></div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431701191171134"
                                data-elem-id="1701191171134"
                                data-elem-type="shape"
                                data-field-top-value="680"
                                data-field-left-value="850"
                                data-field-height-value="104"
                                data-field-width-value="184"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-animate-sbs-event="intoview"
                                data-animate-sbs-trg="1"
                                data-animate-sbs-trgofst="0"
                                data-animate-sbs-loop="loop"
                                data-animate-sbs-opts="[{'ti':'0','mx':'0','my':'0','sx':'1','sy':'1','op':'1','ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':'0','my':'0','sx':'1','sy':'1','op':0,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.1,'sy':1.1,'op':0.1,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.2,'sy':1.2,'op':0.3,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.3,'sy':1.3,'op':0.5,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.4,'sy':1.4,'op':0.7,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.3,'sy':1.3,'op':0.5,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.2,'sy':1.2,'op':0.3,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1.1,'sy':1.1,'op':0.15,'ro':'0','bl':'0','ea':'','dt':'0'},{'ti':'1000','mx':0,'my':'0','sx':1,'sy':1,'op':0,'ro':'0','bl':'0','ea':'','dt':'0'}]"
                                data-field-top-res-320-value="600"
                                data-field-left-res-320-value="20"
                                data-field-top-res-480-value="304"
                                data-field-left-res-480-value="336"
                                data-field-top-res-640-value="473"
                                data-field-left-res-640-value="308"
                                data-field-top-res-960-value="268"
                                data-field-left-res-960-value="661"
                            >
                                <div className="tn-atom"></div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431701084664580"
                                data-elem-id="1701084664580"
                                data-elem-type="button"
                                data-field-top-value="83"
                                data-field-left-value="513"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="315"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="254"
                                data-field-left-res-480-value="12"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="311"
                                data-field-left-res-640-value="12"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="88"
                                data-field-left-res-960-value="407"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">Разработчиков</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702030961492"
                                data-elem-id="1702030961492"
                                data-elem-type="button"
                                data-field-top-value="83"
                                data-field-left-value="813"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="387"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="254"
                                data-field-left-res-480-value="234"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="311"
                                data-field-left-res-640-value="283"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="88"
                                data-field-left-res-960-value="683"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">Аналитиков</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702030965721"
                                data-elem-id="1702030965721"
                                data-elem-type="button"
                                data-field-top-value="223"
                                data-field-left-value="513"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="459"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="331"
                                data-field-left-res-480-value="12"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="407"
                                data-field-left-res-640-value="12"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="204"
                                data-field-left-res-960-value="407"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">Продактов</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702030968509"
                                data-elem-id="1702030968509"
                                data-elem-type="button"
                                data-field-top-value="223"
                                data-field-left-value="813"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="531"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="331"
                                data-field-left-res-480-value="234"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="407"
                                data-field-left-res-640-value="283"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="204"
                                data-field-left-res-960-value="683"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">Дизайнеров</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702030969689"
                                data-elem-id="1702030969689"
                                data-elem-type="button"
                                data-field-top-value="363"
                                data-field-left-value="513"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="603"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="408"
                                data-field-left-res-480-value="12"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="503"
                                data-field-left-res-640-value="12"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="320"
                                data-field-left-res-960-value="407"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">ML специалистов</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702030970756"
                                data-elem-id="1702030970756"
                                data-elem-type="button"
                                data-field-top-value="363"
                                data-field-left-value="813"
                                data-field-height-value="120"
                                data-field-width-value="280"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="675"
                                data-field-left-res-320-value="12"
                                data-field-width-res-320-value="298"
                                data-field-top-res-480-value="408"
                                data-field-left-res-480-value="234"
                                data-field-height-res-480-value="60"
                                data-field-width-res-480-value="206"
                                data-field-top-res-640-value="503"
                                data-field-left-res-640-value="283"
                                data-field-height-res-640-value="80"
                                data-field-width-res-640-value="250"
                                data-field-top-res-960-value="320"
                                data-field-left-res-960-value="683"
                                data-field-height-res-960-value="100"
                                data-field-width-res-960-value="260"
                            >
                                <div className="tn-atom">Проджектов</div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702031064342"
                                data-elem-id="1702031064342"
                                data-elem-type="text"
                                data-field-top-value="80"
                                data-field-left-value="21"
                                data-field-width-value="373"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-width-res-320-value="291"
                                data-field-width-res-480-value="470"
                                data-field-top-res-640-value="59"
                                data-field-left-res-640-value="12"
                                data-field-width-res-640-value="602"
                                data-field-left-res-960-value="11"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1702031064342"
                                >
                                    Мы приглашаем к участию
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6785658431702564744813"
                                data-elem-id="1702564744813"
                                data-elem-type="text"
                                data-field-top-value="210"
                                data-field-left-value="21"
                                data-field-width-value="448"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="145"
                                data-field-width-res-320-value="297"
                                data-field-top-res-480-value="122"
                                data-field-width-res-480-value="460"
                                data-field-top-res-640-value="136"
                                data-field-left-res-640-value="12"
                                data-field-width-res-640-value="589"
                                data-field-left-res-960-value="11"
                                data-field-width-res-960-value="382"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1702564744813"
                                >
                                    Команды от 2 до 5 человек
                                    <br />
                                    Если у вас еще нет своей команды, мы
                                    рекомендуем присоединиться к уже
                                    существующим или создать свою с новыми
                                    участниками, мы поможем это сделать
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec679667442"
                    className="r t-rec t-rec_pt_75 t-rec_pb_120"
                    style={{
                        paddingTop: '75px',
                        paddingBottom: '120px',
                        backgroundColor: '#101110',
                    }}
                    data-record-type="509"
                    data-bg-color="#101110"
                >
                    <div className="t509">
                        <div className="t-section__container t-container">
                            <div className="t-col t-col_12">
                                <div className="t-section__topwrapper t-align_left">
                                    <div
                                        className="t-section__title t-title t-title_xs"
                                        field="btitle"
                                    >
                                        <span
                                            style={{
                                                color: 'rgb(255, 255, 255)',
                                            }}
                                        >
                                            Что дает участие
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="t-card__container t-container">
                            <div className="t509__colwrapper t-col t-col_12">
                                <div className="t-item">
                                    <div
                                        className="t509__col t-col t-col_6 t509__mobileimg"
                                        itemScope
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <meta
                                            itemProp="image"
                                            content="/it-purple-hack/images/prizes.jpg"
                                        />
                                        <div
                                            className="t509__blockimg t-bgimg"
                                            data-original="/it-purple-hack/images/prizes.jpg"
                                            bgimgfield="li_img__8783100789100"
                                            data-image-width="560"
                                            data-image-height="340px"
                                            style={{
                                                backgroundImage:
                                                    "url('/it-purple-hack/images/prizes.jpg')",
                                            }}
                                        ></div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__leftcol">
                                        <div
                                            className="t509__textwrapper t-align_left"
                                            style={{ maxWidth: '600px' }}
                                        >
                                            <div className="t509__content t-valign_bottom">
                                                <div className="t509__box">
                                                    <div
                                                        className="t-card__title t-heading t-heading_xs"
                                                        id="cardtitle1_679667442"
                                                        field="li_title__8783100789100"
                                                    >
                                                        Призовые
                                                    </div>
                                                    <div
                                                        className="t-card__descr t-descr t-descr_sm"
                                                        field="li_descr__8783100789100"
                                                    >
                                                        Выиграй и получи
                                                        денежные призовые, мерч,
                                                        привилегии от компаний,
                                                        а также возможность
                                                        участия во втором
                                                        закрытом дне IT Purple
                                                        Conf
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__rightcol t509__desktopimg">
                                        <div
                                            className="t509__imgwrapper"
                                            itemScope
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <meta
                                                itemProp="image"
                                                content="/it-purple-hack/images/prizes.jpg"
                                            />
                                            <div
                                                className="t509__blockimg t-bgimg"
                                                data-original="/it-purple-hack/images/prizes.jpg"
                                                bgimgfield="li_img__8783100789100"
                                                data-image-width="560"
                                                data-image-height="340px"
                                                style={{
                                                    backgroundImage:
                                                        "url('/it-purple-hack/images/prizes.jpg')",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="t509__separator t-clear"></div>
                                <div className="t-item">
                                    <div
                                        className="t509__col t-col t-col_6 t509__mobileimg"
                                        itemScope
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <meta
                                            itemProp="image"
                                            content="/it-purple-hack/images/projects.jpg"
                                        />
                                        <div
                                            className="t509__blockimg t-bgimg"
                                            data-original="/it-purple-hack/images/projects.jpg"
                                            bgimgfield="li_img__8783100789101"
                                            data-image-width="560"
                                            data-image-height="340px"
                                            style={{
                                                backgroundImage:
                                                    "url('/it-purple-hack/images/projects.jpg')",
                                            }}
                                        ></div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__leftcol t509__desktopimg">
                                        <div
                                            className="t509__imgwrapper"
                                            itemScope
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <meta
                                                itemProp="image"
                                                content="/it-purple-hack/images/projects.jpg"
                                            />
                                            <div
                                                className="t509__blockimg t-bgimg"
                                                data-original="/it-purple-hack/images/projects.jpg"
                                                bgimgfield="li_img__8783100789101"
                                                data-image-width="560"
                                                data-image-height="340px"
                                                style={{
                                                    backgroundImage:
                                                        "url('/it-purple-hack/images/projects.jpg')",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__rightcol">
                                        <div
                                            className="t509__textwrapper t-align_left"
                                            style={{ maxWidth: '600px' }}
                                        >
                                            <div className="t509__content t-valign_bottom">
                                                <div className="t509__box">
                                                    <div
                                                        className="t-card__title t-heading t-heading_xs"
                                                        id="cardtitle2_679667442"
                                                        field="li_title__8783100789101"
                                                    >
                                                        Создание инновационных
                                                        проектов
                                                    </div>
                                                    <div
                                                        className="t-card__descr t-descr t-descr_sm"
                                                        field="li_descr__8783100789101"
                                                    >
                                                        Реализуй свои креативные
                                                        идеи, создавай новые
                                                        продукты или
                                                        усовершенствовуй
                                                        существующие. Прояви
                                                        изобретательность и
                                                        воплоти свои проекты в
                                                        жизнь
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="t509__separator t-clear"></div>
                                <div className="t-item">
                                    <div
                                        className="t509__col t-col t-col_6 t509__mobileimg"
                                        itemScope
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <meta
                                            itemProp="image"
                                            content="/it-purple-hack/images/team.jpg"
                                        />
                                        <div
                                            className="t509__blockimg t-bgimg"
                                            data-original="/it-purple-hack/images/team.jpg"
                                            bgimgfield="li_img__1702290225377"
                                            data-image-width="560"
                                            data-image-height="340px"
                                            style={{
                                                backgroundImage:
                                                    "url('/it-purple-hack/images/team.jpg')",
                                            }}
                                        ></div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__leftcol">
                                        <div
                                            className="t509__textwrapper t-align_left"
                                            style={{ maxWidth: '600px' }}
                                        >
                                            <div className="t509__content t-valign_bottom">
                                                <div className="t509__box">
                                                    <div
                                                        className="t-card__title t-heading t-heading_xs"
                                                        id="cardtitle3_679667442"
                                                        field="li_title__1702290225377"
                                                    >
                                                        Командная работа
                                                    </div>
                                                    <div
                                                        className="t-card__descr t-descr t-descr_sm"
                                                        field="li_descr__1702290225377"
                                                    >
                                                        Разрабатывай кейсы в
                                                        команде, улучшай навыки
                                                        командного
                                                        взаимодействия, развивай
                                                        лидерские качества и
                                                        учись распределять
                                                        задачи внутри команды
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__rightcol t509__desktopimg">
                                        <div
                                            className="t509__imgwrapper"
                                            itemScope
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <meta
                                                itemProp="image"
                                                content="/it-purple-hack/images/team.jpg"
                                            />
                                            <div
                                                className="t509__blockimg t-bgimg"
                                                data-original="/it-purple-hack/images/team.jpg"
                                                bgimgfield="li_img__1702290225377"
                                                data-image-width="560"
                                                data-image-height="340px"
                                                style={{
                                                    backgroundImage:
                                                        "url('/it-purple-hack/images/team.jpg')",
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec679667222"
                    className="r t-rec t-rec_pt_75 t-rec_pb_120"
                    style={{
                        paddingTop: '75px',
                        paddingBottom: '120px',
                        backgroundColor: '#101110',
                    }}
                    data-record-type="509"
                    data-bg-color="#101110"
                >
                    <div className="t509">
                        <div className="t-section__container t-container">
                            <div className="t-col t-col_12">
                                <div className="t-section__topwrapper t-align_left">
                                    <div
                                        className="t-section__title t-title t-title_xs"
                                        field="btitle"
                                    >
                                        <span
                                            style={{
                                                color: 'rgb(255, 255, 255)',
                                            }}
                                        >
                                            Информационные партнеры
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="t-card__container t-container">
                            <div className="t509__colwrapper t-col t-col_12">
                                <div className="t-item">
                                    <div
                                        className="t509__col t-col t-col_6 t509__mobileimg"
                                        itemScope
                                        itemType="http://schema.org/ImageObject"
                                    >
                                        <meta
                                            itemProp="image"
                                            content="/it-purple-hack/images/hack_rus.png"
                                        />
                                        <div
                                            className="t509__blockimg t-bgimg"
                                            data-original="/it-purple-hack/images/hack_rus.png"
                                            bgimgfield="li_img__8783100789107"
                                            data-image-width="560"
                                            data-image-height="240px"
                                            style={{
                                                backgroundImage:
                                                    "url('/it-purple-hack/images/hack_rus.png')",
                                                height: '240px',
                                            }}
                                        ></div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__leftcol">
                                        <div
                                            className="t509__textwrapper t-align_left"
                                            style={{ maxWidth: '600px' }}
                                        >
                                            <div className="t509__content">
                                                <div className="t509__box">
                                                    <div
                                                        className="t-card__title t-heading t-heading_xs"
                                                        id="cardtitle1_679667442"
                                                        field="li_title__8783100789107"
                                                    >
                                                        Хакатоны.рус
                                                    </div>
                                                    <div
                                                        className="t-card__descr t-descr t-descr_sm"
                                                        field="li_descr__8783100789107"
                                                    >
                                                        <a
                                                            href="https://хакатоны.рус/"
                                                            style={{
                                                                color:
                                                                    '#8f00ff',
                                                                textDecoration:
                                                                    'underline',
                                                            }}
                                                        >
                                                            хакатоны.рус
                                                        </a>{' '}
                                                        публикуют информацию о
                                                        локальных, региональных,
                                                        федеральных оффлайн и
                                                        онлайн хакатонах для
                                                        тех, кто хочет вовремя
                                                        узнавать о них.
                                                        <br />С помощью
                                                        хакатонов ты сможешь
                                                        построить карьеру от
                                                        джуна к сеньору или
                                                        своему стартапу.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="t509__col t-col t-col_6 t509__rightcol t509__desktopimg">
                                        <div
                                            className="t509__imgwrapper"
                                            itemScope
                                            itemType="http://schema.org/ImageObject"
                                        >
                                            <meta
                                                itemProp="image"
                                                content="/it-purple-hack/images/hack_rus.png"
                                            />
                                            <div
                                                className="t509__blockimg t-bgimg"
                                                data-original="/it-purple-hack/images/hack_rus.png"
                                                bgimgfield="li_img__8783100789107"
                                                data-image-width="560"
                                                data-image-height="240px"
                                                style={{
                                                    backgroundImage:
                                                        "url('/it-purple-hack/images/hack_rus.png')",
                                                    height: '240px',
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="rec677194115"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="396"
                >
                    <div className="t396">
                        <div
                            className="t396__artboard"
                            data-artboard-recid="677194115"
                            data-artboard-screens="320,480,640,960,1200"
                            data-artboard-height="750"
                            data-artboard-valign="center"
                            data-artboard-upscale="grid"
                            data-artboard-height-res-320="440"
                            data-artboard-height-res-480="580"
                            data-artboard-height-res-640="520"
                            data-artboard-height-res-960="690"
                        >
                            <div
                                className="t396__carrier"
                                data-artboard-recid="677194115"
                            ></div>
                            <div
                                className="t396__filter"
                                data-artboard-recid="677194115"
                            ></div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701282926061"
                                data-elem-id="1701282926061"
                                data-elem-type="image"
                                data-field-top-value="374"
                                data-field-left-value="817"
                                data-field-width-value="201"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-filewidth-value="1408"
                                data-field-fileheight-value="1150"
                                data-field-left-res-480-value="496"
                                data-field-top-res-640-value="340"
                                data-field-left-res-640-value="456"
                                data-field-width-res-640-value="156"
                                data-field-top-res-960-value="295"
                                data-field-left-res-960-value="607"
                                data-field-width-res-960-value="274"
                            >
                                <div className="tn-atom">
                                    <img
                                        className="tn-atom__img t-img"
                                        data-original="/it-purple-hack/images/tild3036-3433-4537-a233-646666373766_____it_purple.png"
                                        alt=""
                                        imgfield="tn_img_1701282926061"
                                    />
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701264542001"
                                data-elem-id="1701264542001"
                                data-elem-type="image"
                                data-field-top-value="99"
                                data-field-left-value="20"
                                data-field-width-value="1162"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-filewidth-value="1680"
                                data-field-fileheight-value="825"
                                data-field-top-res-320-value="89"
                                data-field-top-res-480-value="189"
                                data-field-left-res-480-value="-126"
                                data-field-top-res-640-value="139"
                                data-field-left-res-640-value="-126"
                                data-field-top-res-960-value="179"
                                data-field-left-res-960-value="12"
                                data-field-width-res-960-value="937"
                            >
                                <div className="tn-atom">
                                    <img
                                        className="tn-atom__img t-img"
                                        data-original="/it-purple-hack/images/tild6530-3130-4131-a365-663031313130__2.png"
                                        alt=""
                                        imgfield="tn_img_1701264542001"
                                    />
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701282926044"
                                data-elem-id="1701282926044"
                                data-elem-type="text"
                                data-field-top-value="126"
                                data-field-left-value="314"
                                data-field-width-value="609"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="164"
                                data-field-left-res-320-value="21"
                                data-field-width-res-320-value="282"
                                data-field-width-res-480-value="395"
                                data-field-top-res-640-value="124"
                                data-field-left-res-640-value="25"
                                data-field-top-res-960-value="164"
                                data-field-left-res-960-value="34"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701282926044"
                                >
                                    <strong
                                        style={{
                                            color: 'rgb(255, 255, 255)',
                                            fontWeight: 700,
                                        }}
                                    >
                                        IT Purple HACK
                                    </strong>
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701282926059"
                                data-elem-id="1701282926059"
                                data-elem-type="text"
                                data-field-top-value="225"
                                data-field-left-value="314"
                                data-field-width-value="488"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="211"
                                data-field-left-res-320-value="21"
                                data-field-top-res-480-value="272"
                                data-field-top-res-640-value="223"
                                data-field-left-res-640-value="25"
                                data-field-top-res-960-value="263"
                                data-field-left-res-960-value="34"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701282926059"
                                >
                                    9-15 march 2024;
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701282926065"
                                data-elem-id="1701282926065"
                                data-elem-type="button"
                                data-field-top-value="318"
                                data-field-left-value="314"
                                data-field-height-value="68"
                                data-field-width-value="297"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="278"
                                data-field-left-res-320-value="21"
                                data-field-height-res-320-value="60"
                                data-field-width-res-320-value="279"
                                data-field-top-res-480-value="366"
                                data-field-top-res-640-value="316"
                                data-field-left-res-640-value="25"
                                data-field-top-res-960-value="356"
                                data-field-left-res-960-value="34"
                            >
                                {hasRegistration || true ? (
                                    <Link
                                        className="tn-atom"
                                        to="/dashboard/it-purple-hack"
                                    >
                                        Войти
                                    </Link>
                                ) : (
                                    <Link
                                        className="tn-atom"
                                        to="/events/it-purple-hack"
                                    >
                                        Войти
                                    </Link>
                                )}
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701283046656"
                                data-elem-id="1701283046656"
                                data-elem-type="text"
                                data-field-top-value="152"
                                data-field-left-value="20"
                                data-field-width-value="225"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value=""
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="61"
                                data-field-left-res-320-value="21"
                                data-field-width-res-320-value="289"
                                data-field-top-res-480-value="41"
                                data-field-width-res-480-value="386"
                                data-field-top-res-640-value="81"
                                data-field-left-res-640-value="25"
                                data-field-width-res-640-value="599"
                                data-field-top-res-960-value="121"
                                data-field-left-res-960-value="34"
                                data-field-width-res-960-value="814"
                            >
                                <div
                                    className="tn-atom"
                                    field="tn_text_1701283046656"
                                >
                                    <strong>|</strong>
                                </div>
                            </div>
                            <div
                                className="t396__elem tn-elem tn-elem__6771941151701789534747"
                                data-elem-id="1701789534747"
                                data-elem-type="shape"
                                data-field-top-value="215"
                                data-field-left-value="708"
                                data-field-height-value="3"
                                data-field-width-value="160"
                                data-field-axisy-value="top"
                                data-field-axisx-value="left"
                                data-field-container-value="grid"
                                data-field-topunits-value="px"
                                data-field-leftunits-value="px"
                                data-field-heightunits-value="px"
                                data-field-widthunits-value="px"
                                data-field-top-res-320-value="203"
                                data-field-left-res-320-value="208"
                                data-field-width-res-320-value="70"
                                data-field-top-res-480-value="264"
                                data-field-left-res-480-value="31"
                                data-field-top-res-640-value="214"
                                data-field-top-res-960-value="253"
                                data-field-left-res-960-value="428"
                            >
                                <div className="tn-atom"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="rec676592662"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="635"
                >
                    <div
                        className="t635__textholder"
                        data-recid="677194115"
                        data-text1="Регистрируйся бесплатно на"
                        data-text2="Псс... Это совершенно бесплатно)))"
                        data-text3="Регистрируйся бесплатно на"
                        data-text4="Регистрируйся бесплатно на"
                    ></div>
                </div>
                <div
                    id="rec676592663"
                    className="r t-rec"
                    data-animationappear="off"
                    data-record-type="890"
                >
                    <div
                        className="t890"
                        style={{
                            display: 'none',
                            opacity: 1,
                            position: 'fixed',
                            zIndex: 99990,
                            bottom: '20px',
                            right: '20px',
                        }}
                    >
                        <button
                            type="button"
                            className="t890__arrow"
                            aria-label="Вернуться к началу страницы"
                            style={{
                                boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <svg
                                role="presentation"
                                width="50"
                                height="50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    width="50"
                                    height="50"
                                    rx="50"
                                    fill="#8f00ff"
                                    fillOpacity="0.90"
                                    stroke="none"
                                />
                                <path
                                    d="M16 22l9-9 9 9"
                                    stroke="#ffffff"
                                    strokeWidth="1"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M25 14.2V35.9"
                                    stroke="#ffffff"
                                    strokeWidth="1"
                                    fill="none"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <footer
                    id="t-footer"
                    className="t-records"
                    data-hook="blocks-collection-content-node"
                    data-tilda-project-id="7874881"
                    data-tilda-page-id="41895776"
                    data-tilda-formskey="4ac752927a2ea1d6702ed57387874881"
                    data-tilda-cookie="no"
                    data-tilda-lazy="yes"
                >
                    <div
                        id="rec677196290"
                        className="r t-rec t-rec_pt_30 t-rec_pb_75"
                        style={{
                            paddingTop: '30px',
                            paddingBottom: '75px',
                            backgroundColor: '#101011',
                        }}
                        data-animationappear="off"
                        data-record-type="977"
                        data-bg-color="#101011"
                    >
                        <div className="t977">
                            <div className="t-container t-align_left t977__wrapper">
                                <div
                                    className="t977__col t977__col_left"
                                    style={{ marginBottom: '30px' }}
                                >
                                    <img
                                        className="t977__logo t-img"
                                        src="/it-purple-hack/images/tild3461-6236-4865-a330-393332333037__-__empty__logo_fpmi_white.png"
                                        data-original="/it-purple-hack/images/tild3461-6236-4865-a330-393332333037__logo_fpmi_white.png"
                                        imgfield="img"
                                        alt="ФПМИ"
                                    />
                                </div>
                                <div
                                    className="t977__col t977__col_left"
                                    style={{ marginBottom: '30px' }}
                                >
                                    <img
                                        className="t977__logo t-img"
                                        src="/it-purple-hack/images/gb.svg"
                                        alt="GeekBattle"
                                    />
                                </div>
                                <div className="t977__col_center t977__menu">
                                    <div className="t977__col-inner">
                                        <div
                                            className="t977__descr t-descr"
                                            field="descr"
                                        >
                                            <p
                                                style={{
                                                    color: 'rgb(128, 129, 140)',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                Почта для связи
                                            </p>
                                            <a
                                                href="mailto:fpmimipt@gmail.com"
                                                style={{ fontFamily: 'Arial' }}
                                            >
                                                <u>fpmimipt@gmail.com</u>
                                            </a>
                                            <br />
                                            <a
                                                href="mailto:hello@geekbattle.online"
                                                style={{ fontFamily: 'Arial' }}
                                            >
                                                <u>hello@geekbattle.online</u>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="t977__col-inner"></div>
                                    <div className="t977__col-inner">
                                        <div
                                            className="t977__descr t-descr"
                                            field="descr3"
                                        >
                                            <p
                                                style={{
                                                    color: 'rgb(128, 129, 140)',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                Организаторы
                                            </p>
                                            <a
                                                href="https://t.me/elf_lesnoy"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                style={{
                                                    color: 'rgb(143, 0, 255)',
                                                }}
                                            >
                                                Элизавета Вялых
                                            </a>
                                            <br />
                                            <a
                                                href="https://t.me/tixmav"
                                                target="_blank"
                                                rel="noreferrer noopener"
                                                style={{
                                                    color: 'rgb(143, 0, 255)',
                                                }}
                                            >
                                                Тихон Маврин
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="t977__col t977__col_right">
                                    <div className="footer-links">
                                        <span
                                            className="t977__descr t-descr"
                                            style={{
                                                color: 'rgb(128, 129, 140)',
                                            }}
                                        >
                                            Остались вопросы?
                                        </span>

                                        <a
                                            className="footer-link"
                                            target="_blank"
                                            href="https://t.me/it_purple_hack"
                                        >
                                            <img src="/it-purple-hack/images/tg.svg" />
                                            <span>Информационный канал</span>
                                        </a>

                                        <a
                                            className="footer-link"
                                            target="_blank"
                                            href="https://t.me/it_purple_hack_bot"
                                        >
                                            <img src="/it-purple-hack/images/tg.svg" />
                                            <span>Бот техподдержки</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="footerlinks">
                                <a href="/privacy">
                                    Политика конфиденциальности
                                </a>
                                <a href="https://docs.google.com/document/d/e/2PACX-1vTDW2R5Tky_MKRJni5AVvJRx22MPVUKR9waxiB0hgyrS1yoQ4h2_OqftgvqcAtsmNWgzwYnD8C6fHny/pub">
                                    Условия и положения
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    )
}
