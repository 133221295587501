import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BaseButton from 'components/buttons/BaseButton'
import LogoImg from 'assets/images/logo.svg'
import { ReactComponent as VkIcon } from 'assets/icons/vk.svg'
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram.svg'
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg'
import { ReactComponent as LinkedInIcon } from 'assets/icons/linkedin.svg'
import styles from './Footer.module.scss'

export default () => {
    const { t } = useTranslation()

    const getYear = () => new Date().getFullYear()

    return (
        <div className={styles.container}>
            <div className={styles.block}>
                <Link to="/">
                    <img src={LogoImg} />
                </Link>
                <div className={styles.links}>
                    <BaseButton link="/" styleType="text">
                        {t('Hackathons_')}
                    </BaseButton>
                    {/*<BaseButton link="/" styleType="text">{t('About_platform_')}</BaseButton>
                    <BaseButton link="/" styleType="text">{t('For_organizers_')}</BaseButton>*/}
                </div>
                <div className={styles.contacts}>
                    <BaseButton styleType="text">
                        <a href="mailto:hello@geekbattle.online">
                            hello@geekbattle.online
                        </a>
                    </BaseButton>
                    <div className={styles.socialNetworks}>
                        <a
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <LinkedInIcon />
                        </a>
                        <a
                            className={styles.link}
                            href="https://t.me/geekbattle"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <TelegramIcon />
                        </a>
                        <a
                            className={styles.link}
                            href="https://vk.com/geekbattle"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <VkIcon />
                        </a>
                        <a
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstagramIcon />
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.block}>
                <span>
                    © {getYear()}. GeekBattle. {t('All_rights_reserved_')}
                </span>
                <span className={`${styles.links} ${styles.long}`}>
                    <BaseButton link="/privacy" styleType="text">
                        {t('Privacy_')}
                    </BaseButton>
                    <BaseButton link="/terms" styleType="text">
                        {t('Terms_')}
                    </BaseButton>
                </span>
            </div>
        </div>
    )
}
