import React from 'react'
import { Link } from 'react-router-dom'
import styles from './BaseButton.module.scss'

export default ({
    onClick = () => {},
    link = '',
    styleType = 'primary',
    disabled = false,
    className = '',
    children,
}) => {
    const args = {
        className: `${styles.button} ${styles[styleType]} ${className}`,
        disabled: disabled,
        onClick: onClick,
    }

    return (
        <>
            {link ? (
                <Link to={link} {...args}>
                    {children}
                </Link>
            ) : (
                <button {...args}>{children}</button>
            )}
        </>
    )
}
