import React from 'react'
import { useTranslation } from 'react-i18next'
import EventUtils from 'utils/events'
import styles from './EventPrizeFund.module.scss'

export default ({ event }) => {
    const { t } = useTranslation()

    if (!event.prizeFund && !event.gifts.length) return null

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('Prize_fund_')}</h2>
            {event.prizeFund && (
                <div className={styles.fund}>
                    {!!event.prizeFund.first && (
                        <div className={styles.fundItem}>
                            <span className={styles.fundItemMain}>
                                {EventUtils.transformMoney(
                                    event.prizeFund.first,
                                )}
                            </span>
                            <span className={styles.fundItemHint}>
                                1 {t('Place_')}
                            </span>
                        </div>
                    )}
                    {!!event.prizeFund.second && (
                        <div className={styles.fundItem}>
                            <span className={styles.fundItemMain}>
                                {EventUtils.transformMoney(
                                    event.prizeFund.second,
                                )}
                            </span>
                            <span className={styles.fundItemHint}>
                                2 {t('Place_')}
                            </span>
                        </div>
                    )}
                    {!!event.prizeFund.third && (
                        <div className={styles.fundItem}>
                            <span className={styles.fundItemMain}>
                                {EventUtils.transformMoney(
                                    event.prizeFund.third,
                                )}
                            </span>
                            <span className={styles.fundItemHint}>
                                3 {t('Place_')}
                            </span>
                        </div>
                    )}
                </div>
            )}
            {!!event.gifts.length && (
                <div className={styles.benefits}>
                    {event.prizeFund && <h3>{t('And_also_')}:</h3>}
                    <div className={styles.benefitsItems}>
                        {event.gifts.map((gift, idx) => (
                            <div
                                className={styles.benefitsItem}
                                key={gift.name + idx}
                            >
                                {gift.icon && (
                                    <img
                                        className={styles.benefitsItemIcon}
                                        src={gift.icon.url}
                                    />
                                )}
                                <div className={styles.benefitsItemContent}>
                                    <h6>{gift.name}</h6>
                                    <div>{gift.content}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
