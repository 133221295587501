import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import styles from './EventTasks.module.scss'

const MarkdownText = ({ content, className = '' }) => (
    <div className={`${styles.markdown} ${className}`}>
        {content
            .split('\n')
            .map(i => (i ? <ReactMarkdown source={i} /> : <br />))}
    </div>
)

export default ({ event }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <span className={styles.anchor} id="eventInfo" />
            <h2 className={styles.title}>{t('Tasks_')}</h2>
            <MarkdownText
                className={styles.eventTasks}
                content={event.description}
            />
            {event.requiredResult && (
                <div className={styles.eventResult}>
                    <h3>{t('Required_result_')}</h3>
                    <MarkdownText content={event.requiredResult} />
                </div>
            )}
        </div>
    )
}
