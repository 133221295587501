import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import TagSelect from '../TagSelect'
import BaseInput from 'components/inputs/BaseInput'
import BaseButton from 'components/buttons/BaseButton'
import SwitchInput from 'components/inputs/SwitchInput'
import CheckboxList from 'components/inputs/CheckboxList'
import EventFiltersBlock from '../EventFiltersBlock/EventFiltersBlock'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as QuestionIcon } from 'assets/icons/question.svg'
import { useEventFilters } from 'graphql/queries/events'
import styles from './EventFilters.module.scss'

export default ({ value: filters, onChange, searchQuery, className = '' }) => {
    const { t } = useTranslation()

    const [filtersData] = useEventFilters({
        ...filters,
        query: searchQuery,
    })

    const getFiltersData = filterName =>
        filtersData?.[filterName].map(filter => ({
            content: filter.name,
            key: filter.key,
            info: filter.eventsCount,
        })) || []

    const resetFilters = () => onChange({})
    const updateSpecial = value => onChange({ ...filters, special: value })
    const updateTags = value => onChange({ ...filters, tags: value })
    const updateStatuses = value => onChange({ ...filters, statuses: value })
    const updateFund = value => onChange({ ...filters, fund: value })
    const updateOrganizers = value =>
        onChange({ ...filters, organizers: value })

    const [areAllTagsShowed, setAreAllTagsShowed] = useState(false)

    const [organizersSearch, setOrganizersSearch] = useState('')
    const [areAllOrganizersShowed, setAreAllOrganizersShowed] = useState(false)
    const [remOrganizersCount, setRemOrganizersCount] = useState(0)
    const [organizers, setOrganizers] = useState([])

    useEffect(() => {
        let items = getFiltersData('organizers').filter(i =>
            i.content.toLowerCase().includes(organizersSearch.toLowerCase()),
        )

        if (!areAllOrganizersShowed) {
            setRemOrganizersCount(items.length - 3)
            items = items.slice(0, 3)
        }

        setOrganizers(items)
    }, [filtersData?.organizers, organizersSearch, areAllOrganizersShowed])

    return (
        <div className={`${styles.container} ${className}`}>
            <BaseButton
                className={styles.resetButton}
                styleType="secondary"
                disabled={Object.values(filters).every(
                    val => !val.length && val !== true,
                )}
                onClick={resetFilters}
            >
                {t('Reset_filters_')}
            </BaseButton>
            {/*<div className={styles.specialFilter}>
                <span>{t('Hackathons_for_you_')}</span>
                <QuestionIcon className={styles.specialFilterIcon} />
                <SwitchInput
                    className={styles.specialFilterSwitch}
                    value={filters.special}
                    onChange={updateSpecial}
                />
            </div>*/}
            <EventFiltersBlock
                title={t('Category_')}
                clearable={!!filters.tags?.length}
                onClear={() => updateTags([])}
            >
                <div className={styles.blockContent}>
                    <TagSelect
                        className={styles.filterItems}
                        tags={
                            areAllTagsShowed
                                ? filtersData?.tags
                                : filtersData?.tags.slice(0, 7) || []
                        }
                        value={filters.tags}
                        onChange={updateTags}
                    />
                    {areAllTagsShowed ? (
                        <BaseButton
                            className={styles.showMoreBtn}
                            styleType="text"
                            onClick={() => setAreAllTagsShowed(false)}
                        >
                            {t('Show_less_')}
                        </BaseButton>
                    ) : (
                        filtersData?.tags.length > 7 && (
                            <BaseButton
                                className={styles.showMoreBtn}
                                styleType="text"
                                onClick={() => setAreAllTagsShowed(true)}
                            >
                                {t('Show_more_')} ({filtersData.tags.length - 7}
                                )
                            </BaseButton>
                        )
                    )}
                </div>
            </EventFiltersBlock>
            <EventFiltersBlock
                title={t('Status_')}
                clearable={!!filters.statuses?.length}
                onClear={() => updateStatuses([])}
            >
                <CheckboxList
                    className={styles.filterItems}
                    items={getFiltersData('statuses')}
                    value={filters.statuses}
                    onChange={updateStatuses}
                />
            </EventFiltersBlock>
            <EventFiltersBlock
                title={t('Prize_fund_')}
                clearable={!!filters.fund?.length}
                onClear={() => updateFund([])}
            >
                <CheckboxList
                    className={styles.filterItems}
                    items={getFiltersData('fund')}
                    value={filters.fund}
                    onChange={updateFund}
                />
            </EventFiltersBlock>
            <EventFiltersBlock
                title={t('Organizer_')}
                clearable={!!filters.organizers?.length}
                onClear={() => updateOrganizers([])}
            >
                <div className={styles.blockContent}>
                    <BaseInput
                        className={styles.filterInput}
                        value={organizersSearch}
                        onChange={setOrganizersSearch}
                        placeholder={t('Search_')}
                        icon={<SearchIcon />}
                    />
                    <CheckboxList
                        className={styles.filterItems}
                        items={organizers}
                        value={filters.organizers}
                        onChange={updateOrganizers}
                    />
                    {areAllOrganizersShowed
                        ? organizers.length > 3 && (
                              <BaseButton
                                  className={styles.showMoreBtn}
                                  styleType="text"
                                  onClick={() =>
                                      setAreAllOrganizersShowed(false)
                                  }
                              >
                                  {t('Show_less_')}
                              </BaseButton>
                          )
                        : remOrganizersCount > 0 && (
                              <BaseButton
                                  className={styles.showMoreBtn}
                                  styleType="text"
                                  onClick={() =>
                                      setAreAllOrganizersShowed(true)
                                  }
                              >
                                  {t('Show_more_')} ({remOrganizersCount})
                              </BaseButton>
                          )}
                </div>
            </EventFiltersBlock>
        </div>
    )
}
