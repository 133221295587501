import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useRouteMatch, Link } from 'react-router-dom'
import Carousel from 'components/generic/Carousel'
import BaseButton from 'components/buttons/BaseButton'
import EventStatus from 'components/events/EventStatus'
import EventTagList from 'components/events/EventTagList'
import EventUtils from 'utils/events'
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg'
import { ReactComponent as MoneyIcon } from 'assets/icons/money.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg'
import IntroImg from 'assets/images/intro.png'
import DefaultCoverImg from 'assets/images/default_cover_image.png'
import styles from './EventHeader.module.scss'

const Organizer = ({ data: organizer }) => (
    <span className={styles.eventOrganizerImageContainer}>
        <img
            className={styles.eventOrganizerImage}
            src={organizer.icon}
            key={organizer.slug}
            title={organizer.name}
        ></img>
    </span>
)

export default ({ event, registration }) => {
    const { t } = useTranslation()

    const routeMatch = useRouteMatch()

    const hasRegistration = registration
        ? registration.status !== 'incomplete'
        : false

    const isRegistrationOpen = moment().isBetween(
        event.registrationStartTime,
        event.registrationEndTime,
    )

    return (
        <div
            className={styles.container}
            style={{
                background: `linear-gradient(180deg, rgba(36, 33, 50, 0) 77.92%, #242132 100%), url(${
                    event.coverImage?.url || DefaultCoverImg
                })`,
            }}
        >
            <div className={styles.content}>
                <div className={styles.navbar}>
                    <BaseButton
                        className={styles.navbarLink}
                        link="/"
                        styleType="text"
                    >
                        {t('Main_page_')}
                    </BaseButton>
                    <ChevronRightIcon className={styles.navbarIcon} />
                    <BaseButton
                        className={`${styles.navbarLink} ${styles.active}`}
                        link={`/events/${event.slug}`}
                        styleType="text"
                    >
                        {event.name}
                    </BaseButton>
                </div>
                <div className={styles.left}>
                    <div className={styles.leftTop}>
                        <div className={styles.eventTagsContainer}>
                            <EventStatus
                                className={styles.eventStatus}
                                event={event}
                            />
                            <EventTagList
                                itemClassName={styles.eventTag}
                                items={event.categories}
                            />
                        </div>
                        <div className={styles.eventTitleContainer}>
                            <h1 className={styles.eventTitle}>{event.name}</h1>
                            <EventStatus
                                className={styles.eventStatus}
                                event={event}
                            />
                        </div>
                        {event.miniDescription && (
                            <div className={styles.eventDescription}>
                                {event.miniDescription}
                            </div>
                        )}
                        <div
                            className={
                                hasRegistration
                                    ? styles.eventRegistration
                                    : styles.eventButtons
                            }
                        >
                            {hasRegistration ? (
                                <div
                                    className={
                                        styles.eventRegistrationTextContainer
                                    }
                                >
                                    <CheckIcon
                                        className={
                                            styles.eventRegistrationGreen
                                        }
                                    />
                                    <div
                                        className={styles.eventRegistrationText}
                                    >
                                        <span
                                            className={
                                                styles.eventRegistrationGreen
                                            }
                                        >
                                            {t(
                                                'You_are_registered_for_the_event_',
                                            )}
                                        </span>
                                        <span>
                                            {t(
                                                'All_event_information_is_in_account_',
                                            )}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                isRegistrationOpen && (
                                    <BaseButton
                                        className={styles.eventLongButton}
                                        link={`${routeMatch.url}/register`}
                                    >
                                        {t('Take_part_')}
                                    </BaseButton>
                                )
                            )}
                            <div className={styles.eventButtons}>
                                {hasRegistration && (
                                    <BaseButton
                                        link={`/dashboard/${event.slug}`}
                                        className={styles.eventLongButton}
                                    >
                                        {t('To_the_event_')}
                                    </BaseButton>
                                )}
                                <BaseButton
                                    styleType="text"
                                    onClick={() =>
                                        document
                                            .querySelector('#eventInfo')
                                            .scrollIntoView()
                                    }
                                >
                                    <span className={styles.moreTextDesktop}>
                                        {t('More_about_event_')}
                                    </span>
                                    <span className={styles.moreTextMobile}>
                                        {t('More_')}
                                    </span>
                                </BaseButton>
                            </div>
                        </div>
                    </div>
                    <div className={styles.leftBottom}>
                        <div className={styles.leftBottomItem}>
                            <div className={styles.leftBottomItemHeader}>
                                <CalendarIcon />
                                <span>{t('Event_date_')}</span>
                            </div>
                            <div>{EventUtils.getEventDates(event)}</div>
                        </div>
                        <div className={styles.leftBottomItem}>
                            <div className={styles.leftBottomItemHeader}>
                                <MoneyIcon />
                                <span>{t('Prize_fund_')}</span>
                            </div>
                            <div>
                                {EventUtils.transformMoney(
                                    event.prizeFund?.total,
                                )}
                            </div>
                        </div>
                        <div className={styles.leftBottomItem}>
                            <div className={styles.leftBottomItemHeader}>
                                <UsersIcon />
                                <span>{t('Participants_')}</span>
                            </div>
                            <div>{event._participantsCount}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.rightTop}>
                        <img
                            src={event.logo?.url || IntroImg}
                            className={styles.eventImage}
                        />
                    </div>
                    {!!event.organizations.length && (
                        <div className={styles.rightBottom}>
                            <h5 className={styles.rightBottomTitle}>
                                {t('Organizers_')}
                            </h5>
                            {event.organizations.length === 1 ? (
                                <Organizer data={event.organizations[0]} />
                            ) : (
                                <Carousel
                                    className={styles.eventOrganizersContainer}
                                    itemsClassName={styles.eventOrganizers}
                                    items={event.organizations}
                                    itemsPerSlide={2}
                                    noMobile
                                >
                                    {organizer => (
                                        <Organizer data={organizer} />
                                    )}
                                </Carousel>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
