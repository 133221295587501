import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BurgerMenu from 'components/generic/BurgerMenu'
import BaseButton from 'components/buttons/BaseButton'
import * as AuthSelectors from 'redux/auth/selectors'
import { useMyProfilePreview } from 'graphql/queries/userProfile'
import LogoImg from 'assets/images/logo.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import styles from './GlobalNavBar.module.scss'

export default () => {
    const { t, i18n } = useTranslation()

    const isAuthenticated = useSelector(AuthSelectors.isAuthenticated)
    const hasSuperAdmin = useSelector(AuthSelectors.hasSuperAdmin)
    const hasOrganiserAccess = useSelector(AuthSelectors.hasOrganiserAccess)
    const hasRecruiterAccess = useSelector(AuthSelectors.hasRecruiterAccess)
    const [profile] = useMyProfilePreview()

    const getUserLinks = () => {
        const links = [
            { content: t('Dashboard_'), link: '/account' },
            { content: t('Edit_profile_'), link: '/account/profile' },
        ]

        if (hasOrganiserAccess) {
            links.push({ content: t('ManageEvent'), link: '/organise' })
        }

        if (hasRecruiterAccess) {
            links.push({ content: t('Recruitment'), link: '/recruitment' })
        }

        if (hasSuperAdmin) {
            links.push({ content: t('Admin'), link: '/admin' })
        }

        links.push({
            content: (
                <span className={styles.logout}>
                    <LogoutIcon />
                    {t('Log_out_')}
                </span>
            ),
            link: '/logout',
        })

        return links
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.leftBlock}>
                    <Link to="/">
                        <img src={LogoImg} />
                    </Link>
                    <div className={styles.links}>
                        {/*<BaseButton link="/" styleType="text">{t('About_platform_')}</BaseButton>
                    <BaseButton link="/" styleType="text">{t('For_organizers_')}</BaseButton>*/}
                    </div>
                </div>
                <div className={styles.rightBlock}>
                    <BurgerMenu
                        itemClassName={styles.burgerMenuItem}
                        items={[
                            {
                                content: 'Русский',
                                onClick: () => i18n.changeLanguage('ru'),
                            },
                            {
                                content: 'English',
                                onClick: () => i18n.changeLanguage('en'),
                            },
                        ]}
                    >
                        <BaseButton styleType="text">
                            {i18n.language.toUpperCase()}
                        </BaseButton>
                    </BurgerMenu>
                    {isAuthenticated ? (
                        <BurgerMenu
                            itemClassName={styles.burgerMenuItem}
                            items={getUserLinks()}
                        >
                            <BaseButton
                                className={styles.user}
                                styleType="text"
                            >
                                {profile?.avatar ? (
                                    <img
                                        className={styles.userImage}
                                        src={profile.avatar}
                                    />
                                ) : (
                                    <span className={styles.userImage}>
                                        {profile?.firstName[0]}
                                        {profile?.lastName[0]}
                                    </span>
                                )}
                                <span className={styles.userName}>
                                    {profile?.firstName} {profile?.lastName[0]}.
                                </span>
                            </BaseButton>
                        </BurgerMenu>
                    ) : (
                        <BaseButton link="/login" styleType="secondary">
                            {t('Login_')}
                        </BaseButton>
                    )}
                    {/*<BurgerMenu
                    className={styles.burgerMenu}
                    childrenClassName={styles.burgerMenuIconContainer}
                    itemClassName={styles.burgerMenuItem}
                    items={[
                        { content: t('About_platform_'), link: '/' },
                        { content: t('For_organizers_'), link: '/' },
                    ]}
                >
                    <MenuIcon />
                </BurgerMenu>*/}
                </div>
            </div>
        </div>
    )
}
