import React from 'react'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import styles from './CheckboxList.module.scss'

export default ({ items, value: activeItems = [], onChange, className }) => {
    const toggleItem = key => {
        if (activeItems.includes(key)) {
            const idx = activeItems.indexOf(key)
            onChange([
                ...activeItems.slice(0, idx),
                ...activeItems.slice(idx + 1),
            ])
        } else {
            onChange([...activeItems, key])
        }
    }

    return (
        <div className={`${styles.container} ${className}`}>
            {items.map(item => (
                <div
                    className={`${styles.item} ${
                        activeItems.includes(item.key) ? styles.active : ''
                    }`}
                    key={item.key}
                    onClick={() => toggleItem(item.key)}
                >
                    <span className={styles.checkbox}>
                        <CheckIcon />
                    </span>
                    <span>{item.content}</span>
                    <span className={styles.info}>{item.info}</span>
                </div>
            ))}
        </div>
    )
}
