import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BaseInput from 'components/inputs/BaseInput'
import BaseButton from 'components/buttons/BaseButton'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icons/chevron-right.svg'
import EmailService from 'services/email'
import * as SnackbarActions from 'redux/snackbar/actions'
import styles from './EventOrganizersCard.module.scss'

const MoreLink = ({ mobile = false }) => {
    const { t } = useTranslation()

    return (
        <Link
            className={`${styles.moreLink} ${
                mobile ? styles.mobile : styles.desktop
            }`}
            to="/"
        >
            <span className={`${styles.moreLinkText} ${styles.desktop}`}>
                {t('More_about_event_organization_')}
            </span>
            <span className={`${styles.moreLinkText} ${styles.mobile}`}>
                {t('More_')}
            </span>
            <ChevronRightIcon />
        </Link>
    )
}

export default () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [isSended, setIsSended] = useState(false)

    const sendApplication = async e => {
        e.preventDefault()

        let body = 'Кто-то хочет организовать хакатон на GeekBattle!\n\n'
        body += `Имя: ${name}\nТелефон: ${phone}`

        EmailService.sendContactEmail({
            subject: 'Заявка на регистрацию хакатона',
            body,
        })
            .then(() => {
                setName('')
                setPhone('')
                setIsSended(true)
            })
            .catch(() => dispatch(SnackbarActions.error(t('Something_wrong_'))))
    }

    return (
        <div className={styles.container}>
            <div className={styles.textBlock}>
                <div className={styles.textBlockTop}>
                    <h4>{t('Want_to_organize_an_event_')}</h4>
                    <span className={styles.contactText}>
                        {t('Contact_us_for_event_organization_')}
                    </span>
                </div>
                <MoreLink />
            </div>
            {isSended ? (
                <div className={styles.sendedBlock}>
                    <CheckIcon className={styles.sendedBlockIcon} />
                    <div className={styles.sendedBlockText}>
                        <span>{t('Application_is_sended_')}. </span>
                        <span>{t('Wait_for_a_call_')}</span>
                    </div>
                </div>
            ) : (
                <form className={styles.formBlock} onSubmit={sendApplication}>
                    <div className={styles.formBlockFields}>
                        <BaseInput
                            value={name}
                            onChange={setName}
                            className={styles.formBlockInput}
                            placeholder={t('Your_name_')}
                            required
                        />
                        <BaseInput
                            value={phone}
                            onChange={setPhone}
                            className={styles.formBlockInput}
                            placeholder={t('Your_phone_')}
                            type="tel"
                            required
                        />
                        <BaseButton
                            className={`${styles.formBlockSubmitBtn} ${styles.mobile}`}
                            styleType="secondary"
                            disabled={!name && !phone}
                        >
                            {t('Send_')}
                        </BaseButton>
                    </div>
                    <div className={styles.formBlockSubmit}>
                        <BaseButton
                            className={`${styles.formBlockSubmitBtn} ${styles.desktop}`}
                            styleType="secondary"
                            disabled={!name && !phone}
                        >
                            {t('Send_')}
                        </BaseButton>
                        <div className={styles.privacy}>
                            {t('You_agree_to_')}{' '}
                            <BaseButton
                                className={styles.privacyLink}
                                link="/privacy"
                                styleType="text"
                            >
                                {t('Personal_data_processing_')}
                            </BaseButton>
                        </div>
                    </div>
                </form>
            )}
            <MoreLink mobile />
        </div>
    )
}
