import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import BaseButton from 'components/buttons/BaseButton'
import TeamsService from 'services/teams'
import ProjectsService from 'services/projects'
import styles from './EventResults.module.scss'

export default ({ event }) => {
    const { t } = useTranslation()

    const [activeCaseSlug, setActiveCaseSlug] = useState(
        event.challengesEnabled ? event.challenges[0].slug : null,
    )
    const [winners, setWinners] = useState({})
    const [teams, setTeams] = useState([])

    const getTeamName = id => {
        const team = teams.find(i => i._id === id)
        return team?.name || '...'
    }

    useEffect(() => {
        const getTeams = async () => {
            const teamsData = await TeamsService.getTeamNamesForEvent(event._id)
            setTeams(teamsData)
        }

        const findChallengeWinners = (challengeSlug, projects) => {
            const challengeCheckpoints = event.checkpoints
                .filter(i => i.challenge === challengeSlug)
                .map(i => i._id)

            const challengeWinners = projects
                .filter(
                    project =>
                        event.finalists.includes(project._id) &&
                        (!event.challengesEnabled ||
                            project.challenges.includes(challengeSlug)),
                )
                .map(project => {
                    let score = 0

                    for (const chlCheckpoint of challengeCheckpoints) {
                        score += project.score.checkpoints
                            .filter(
                                i =>
                                    i.checkpoint === chlCheckpoint &&
                                    i.value !== null,
                            )
                            .reduce(
                                (res, i, _idx, arr) =>
                                    res + i.value / arr.length,
                                0,
                            )
                    }

                    score += project.score.defense
                        .filter(
                            i =>
                                i.challenge === challengeSlug &&
                                i.value !== null,
                        )
                        .reduce(
                            (res, i, _idx, arr) => res + i.value / arr.length,
                            0,
                        )

                    score += project.score.stopCodding
                        .filter(
                            i =>
                                i.challenge === challengeSlug &&
                                i.value !== null,
                        )
                        .reduce(
                            (res, i, _idx, arr) => res + i.value / arr.length,
                            0,
                        )

                    return { score, data: project }
                })
                .sort((a, b) => b.score - a.score)
                .slice(0, 3)

            return challengeWinners
        }

        const findWinners = async () => {
            const projects = await ProjectsService.getProjectsByEvent(
                event.slug,
            )

            if (event.challengesEnabled) {
                for (const challenge of event.challenges) {
                    setWinners(oldWinners => ({
                        ...oldWinners,
                        [challenge.slug]: findChallengeWinners(
                            challenge.slug,
                            projects,
                        ),
                    }))
                }
            } else {
                setWinners({ null: findChallengeWinners(null, projects) })
            }
        }

        getTeams()
        findWinners()
    }, [])

    if (moment().isBefore(event.endTime)) return null

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>{t('Results__')}</h2>
            {event.challengesEnabled && (
                <div className={styles.challenges}>
                    {event.challenges.map(challenge => (
                        <BaseButton
                            className={`${styles.challenge} ${
                                activeCaseSlug === challenge.slug
                                    ? styles.active
                                    : ''
                            }`}
                            key={challenge.slug}
                            onClick={() => setActiveCaseSlug(challenge.slug)}
                            styleType="secondary"
                        >
                            {challenge.name}
                        </BaseButton>
                    ))}
                </div>
            )}
            {!!Object.keys(winners).length && (
                <div className={styles.places}>
                    {winners[activeCaseSlug].map((winner, idx) => (
                        <div className={styles.place} key={winner.data.name}>
                            <div className={styles.placeInfo}>
                                <h2 className={styles.placeTitle}>
                                    {idx + 1} {t('Place_')}
                                </h2>
                                <h4>{getTeamName(winner.data.team)}</h4>
                            </div>
                            {event.galleryOpen && (
                                <BaseButton
                                    link={`/projects/${event.slug}/view/${winner.data._id}`}
                                    styleType="text"
                                >
                                    {t('View_work_')}
                                </BaseButton>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {event.galleryOpen && (
                <BaseButton
                    className={styles.pageBtn}
                    link={`/projects/${event.slug}`}
                    styleType="secondary"
                >
                    {t('View_all_works_')}
                </BaseButton>
            )}
        </div>
    )
}
