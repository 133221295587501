import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import EventStatus from 'components/events/EventStatus'
import EventOrganizersCard from '../EventOrganizersCard'
import EventTagList from 'components/events/EventTagList'
import EventUtils from 'utils/events'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { ReactComponent as MoneyIcon } from 'assets/icons/money.svg'
import { ReactComponent as UsersIcon } from 'assets/icons/users.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import styles from './EventList.module.scss'

export default ({
    events,
    infiniteScroll = true,
    onScrollBottom = () => {},
}) => {
    const { t } = useTranslation()

    const containerRef = useRef()
    const [isInfiniteScrollEnabled, setIsInfiniteScrollEnabled] = useState(true)

    const onScroll = () => {
        if (
            window.scrollY >=
            containerRef.current.offsetHeight +
                containerRef.current.offsetTop -
                window.innerHeight
        ) {
            setIsInfiniteScrollEnabled(false)
            onScrollBottom()
        }
    }

    useEffect(() => {
        if (infiniteScroll && !isInfiniteScrollEnabled)
            setIsInfiniteScrollEnabled(true)
    }, [infiniteScroll])

    useEffect(() => {
        if (infiniteScroll && isInfiniteScrollEnabled) {
            document.addEventListener('scroll', onScroll)
            return () => {
                document.removeEventListener('scroll', onScroll)
            }
        }
    }, [isInfiniteScrollEnabled, onScrollBottom])

    const getEventOrganizers = event => {
        const text = event.organizations.map(i => i && i.name).join(', ')

        if (text.length > 20) {
            return text.slice(0, 20) + '...'
        } else {
            return text
        }
    }

    return (
        <div ref={containerRef} className={styles.container}>
            {events &&
                events.map((event, idx) => (
                    <>
                        <Link
                            className={styles.event}
                            to={`/events/${event.slug}`}
                            key={event.slug}
                        >
                            {event.previewImage && (
                                <img
                                    className={styles.eventImage}
                                    src={event.previewImage.url}
                                />
                            )}
                            <div className={styles.eventData}>
                                <div className={styles.eventDataHeader}>
                                    <EventStatus event={event} />
                                    <EventTagList
                                        className={styles.eventTags}
                                        items={event.categories}
                                    />
                                </div>
                                <h5>{event.name}</h5>
                                {event.miniDescription && (
                                    <div className={styles.eventDescription}>
                                        {event.miniDescription}
                                    </div>
                                )}
                                <div className={styles.eventDataFooter}>
                                    <div className={styles.eventDataFooterItem}>
                                        <div
                                            className={
                                                styles.eventDataFooterItemHeader
                                            }
                                        >
                                            <CalendarIcon
                                                className={
                                                    styles.eventDataFooterItemHeaderIcon
                                                }
                                            />
                                            <span>{t('Event_date_')}</span>
                                        </div>
                                        <div>
                                            {EventUtils.getEventDates(event)}
                                        </div>
                                    </div>
                                    <div className={styles.eventDataFooterItem}>
                                        <div
                                            className={
                                                styles.eventDataFooterItemHeader
                                            }
                                        >
                                            <MoneyIcon
                                                className={
                                                    styles.eventDataFooterItemHeaderIcon
                                                }
                                            />
                                            <span>{t('Prize_fund_')}</span>
                                        </div>
                                        <div>
                                            {EventUtils.transformMoney(
                                                event.prizeFund?.total,
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.eventDataFooterItem}>
                                        <div
                                            className={
                                                styles.eventDataFooterItemHeader
                                            }
                                        >
                                            <UsersIcon
                                                className={
                                                    styles.eventDataFooterItemHeaderIcon
                                                }
                                            />
                                            <span>{t('Participants_')}</span>
                                        </div>
                                        <div>{event._participantsCount}</div>
                                    </div>
                                    {!!event.organizations.length && (
                                        <div
                                            className={
                                                styles.eventDataFooterItem
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.eventDataFooterItemHeader
                                                }
                                            >
                                                <UserIcon
                                                    className={
                                                        styles.eventDataFooterItemHeaderIcon
                                                    }
                                                />
                                                <span>{t('Organizers_')}</span>
                                            </div>
                                            <div>
                                                {getEventOrganizers(event)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Link>
                        {idx === 3 && <EventOrganizersCard />}
                    </>
                ))}
            {events?.length < 4 && <EventOrganizersCard />}
        </div>
    )
}
