import React, { useMemo } from 'react'

import { Box } from '@material-ui/core'
import { RegistrationFields } from '@hackjunction/shared'
import TextInput from 'components/inputs/TextInput'
import TextAreaInput from 'components/inputs/TextAreaInput'
import PhoneNumberInput from 'components/inputs/PhoneNumberInput'
import JobRoleInput from 'components/inputs/JobRoleInput'
import SkillsInput from 'components/inputs/SkillsInput'
import DateInput from 'components/inputs/DateInput'
import FormControl from 'components/inputs/FormControl'
import Select from 'components/inputs/Select'
import EducationInput from 'components/inputs/EducationInput'
import BooleanInput from 'components/inputs/BooleanInput'
import RecruitmentOptionInput from 'components/inputs/RecruitmentOptionInput'
import TeamOptionInput from 'components/inputs/TeamOptionInput'
import { useTranslation } from 'react-i18next'
const { fieldTypes } = RegistrationFields
// TODO URL-input

const RegistrationQuestion = ({
    field,
    form,
    config,
    required,
    autoFocus,
    isCustom,
}) => {
    const { t } = useTranslation()

    const renderInput = () => {
        switch (config.fieldType.id) {
            case fieldTypes.URL.id:
            case fieldTypes.EMAIL.id:
            case fieldTypes.SHORT_TEXT.id:
                return (
                    <TextInput
                        autoFocus={autoFocus}
                        name={field.name}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.PHONE_NUMBER.id:
                return (
                    <PhoneNumberInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={value => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.DATE.id:
                return (
                    <DateInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={date => form.setFieldValue(field.name, date)}
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.GENDER.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={gender =>
                            form.setFieldValue(field.name, gender)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="gender"
                    />
                )
            case fieldTypes.NATIONALITY.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={nationality =>
                            form.setFieldValue(field.name, nationality)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="nationality"
                    />
                )
            case fieldTypes.LANGUAGES.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={languages =>
                            form.setFieldValue(field.name, languages)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="language"
                        isMulti={true}
                    />
                )
            case fieldTypes.COUNTRY.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={country =>
                            form.setFieldValue(field.name, country)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="country"
                    />
                )
            case fieldTypes.ROLES.id:
                return (
                    <JobRoleInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={roles =>
                            form.setFieldValue(field.name, roles)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.SKILLS.id:
                return (
                    <SkillsInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={skills =>
                            form.setFieldValue(field.name, skills)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.INDUSTRIES.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="industry"
                        isMulti={true}
                    />
                )
            case fieldTypes.THEMES.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="theme"
                        isMulti={true}
                    />
                )
            case fieldTypes.EDUCATION.id:
                return (
                    <EducationInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.LONG_TEXT.id:
                return (
                    <TextAreaInput
                        autoFocus={autoFocus}
                        name={field.name}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.NUM_HACKATHONS.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="num-hackathons"
                    />
                )
            case fieldTypes.T_SHIRT_SIZE.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        options="t-shirt-size"
                    />
                )
            case fieldTypes.BOOLEAN.id:
                return (
                    <BooleanInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.DIETARY_RESTRICTIONS.id:
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={t(config.label)}
                        options="dietary-restriction"
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        isMulti={true}
                    />
                )
            case fieldTypes.TEAM_OPTIONS.id:
                return (
                    <TeamOptionInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case fieldTypes.RECRUITMENT_OPTIONS.id:
                return (
                    <RecruitmentOptionInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            default:
                return null
        }
    }

    const renderCustomInput = () => {
        switch (config.fieldType) {
            case 'text':
                return (
                    <TextInput
                        autoFocus={autoFocus}
                        name={field.name}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case 'textarea':
                return (
                    <TextAreaInput
                        autoFocus={autoFocus}
                        name={field.name}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case 'boolean':
                return (
                    <BooleanInput
                        autoFocus={autoFocus}
                        value={field.value}
                        onChange={value =>
                            form.setFieldValue(field.name, value)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case 'single-choice':
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={'Choose one'}
                        options={config.settings.options.map(option => ({
                            value: option,
                            label: option,
                        }))}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                    />
                )
            case 'multiple-choice':
                return (
                    <Select
                        autoFocus={autoFocus}
                        label={'Choose many'}
                        options={config.settings.options.map(option => ({
                            value: option,
                            label: option,
                        }))}
                        value={field.value}
                        onChange={items =>
                            form.setFieldValue(field.name, items)
                        }
                        onBlur={() => form.setFieldTouched(field.name)}
                        isMulti={true}
                    />
                )
            default:
                return null
        }
    }

    const renderSwitch = isCustom => {
        const hint = config.hint.replace(/\\n/g, '\n')

        return (
            <FormControl
                label={t(config.label)}
                error={form.errors[field.name]}
                touched={form.touched[field.name]}
                hint={isCustom ? hint : t(hint)}
                required={
                    config.alwaysRequired ||
                    config.fieldRequired ||
                    config.required_
                }
            >
                {isCustom ? renderCustomInput() : renderInput()}
            </FormControl>
        )
    }

    return <Box display="flex">{renderSwitch(isCustom)}</Box>
}

export default RegistrationQuestion
